import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import "../Weather/weather.css"

const Agenda = () => {
  const history = useHistory()
  const [selectedImage, setSelectedimage] = useState(1)
  const [isPgload, setisPgload] = useState(false)

  const loader = document.querySelector(".loader")
  const handleClick = num => {
    setSelectedimage(num)
  }

  const images = {
    1: "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FAgenda%20TP%20Day%201.png?alt=media",
    2: "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FAgenda%20TP%20Day%202.png?alt=media",
    3: "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FAgenda%20TP%20Day%203.png?alt=media",
    4: "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FAgenda%20TP%20Day%204.png?alt=media",
  }

  setTimeout(() => {
    loader.classList.add("loader--hide")
    setisPgload(true)
  }, 2000)
  return (
    <React.Fragment>
      <div
        className="main-div"
        style={{
          backgroundColor: "transparent",
          fontFamily: "HelveticaCondensed",
          marginTop: "50px",
        }}
      >
        <div style={{ position: "relative", width: "100vw" }}>
          <img loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FAgenda%20TP.png?alt=media&token=097019d7-d00f-4f4a-95f4-1a4d3c129cb9"
            width="100%"
          />

          <div
            className="speakers-btn"
            style={{
              position: "absolute",
              top: "9%",
              left: "20%",
              height: "7%",
              width: "60%",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push("/page/Speakers")
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              flexDirection: "row",
              top: "17%",
              padding: "10px 20px",
              width: "100%",
              fontSize: "25px",
              color: "#fff",
              cursor: "pointer",
            }}
          > 
            <div className={`day-btn ${selectedImage === 1 ? 'clicked' : ''}`} onClick={() => handleClick(1)}>Day 1</div>
            <div className={`day-btn ${selectedImage === 2 ? 'clicked' : ''}`} onClick={() => handleClick(2)}>Day 2</div>
            <div className={`day-btn ${selectedImage === 3 ? 'clicked' : ''}`} onClick={() => handleClick(3)}>Day 3</div>
            <div className={`day-btn ${selectedImage === 4 ? 'clicked' : ''}`} onClick={() => handleClick(4)}>Day 4</div>
          </div>

          <div style={{ position: "absolute", top: "27%" }}>
            <img loading="lazy" src={images[selectedImage]} width="100%" alt={selectedImage} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Agenda
