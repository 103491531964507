import { OPEN_MODAL, OPEN_RC, OPEN_MS, OPEN_TOUR, OPEN_WELCOME, NOTIFY, OPEN_ANN,OPEN_ANN2 } from "./actionTypes"

const INIT_STATE = {
  modal: false,
  rc: false,
  ms: false,
  tour: false,
  welcome: false,
  ann: false,
  ann2: false,
  noti: false,
}

const agenda = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modal: !state.modal,
      }
    case OPEN_RC:
      return {
        ...state,
        rc: !state.rc,
      }
    case OPEN_MS:
      return {
        ...state,
        ms: !state.ms,
      }
    case OPEN_TOUR:
      return {
        ...state,
        tour: !state.tour,
      }
    case OPEN_WELCOME:
      return {
        ...state,
        welcome: !state.welcome,
      }
    case OPEN_ANN:
      return {
        ...state,
        ann: !state.ann,
      }
      case OPEN_ANN2:
        return {
          ...state,
          ann2: !state.ann2,
        }
       
    case NOTIFY:
      return {
        ...state,
        noti: !state.noti,
      }
    default:
      return state
  }
}

export default agenda
