import React, { useEffect, useState } from "react"
import "./emoji.css"
import io from "socket.io-client"

const FloatingEmoji = ({stylex}) => {
    console.log("props", stylex)
  const [obj, setObj] = useState(JSON.parse(localStorage.getItem("authUser")))
  const socket = io.connect(process.env.REACT_APP_ADMIN_Notification)

  function emojii(emojiIndex, userName) {
    const button = document.querySelector(`button[class="${emojiIndex}"]`)
    const container = window.document.querySelector(".emoji-container")

    if (!button || !container) return

    const emojiEl = document.createElement("div")
    emojiEl.classList.add("emoji-animate")

    const { innerHTML } = button
    emojiEl.innerHTML = `
      <div class="emoji">${innerHTML}</div>
      <div class="username">${userName}</div>
    `

    container.appendChild(emojiEl)

    const { height, left } = button.getBoundingClientRect()
    const { bottom, top, width } = container.getBoundingClientRect()

    const animation = emojiEl.animate(
      [
        {
          opacity: 1,
          transform: `translate(${left-40}px, ${bottom-10}px)`,
        },
        {
          opacity: 0,
          transform: `translate(${width / 10}px, ${top - height}px)`,
        },
      ],
      {
        duration: 2100,
        easing: "cubic-bezier(.17,.67,.83,.67)",
      }
    )

    animation.onfinish = () => emojiEl.remove()
  }

  function handleEmojiClick(e) {
    const emojiIndex = e.target.className 

    const emojiData = {
      emojiIndex: emojiIndex,
      id: obj?._id,
      Firstname: obj?.Firstname,
    }

    // socket.emit("emojireact", emojiData)

    emojii(emojiIndex, "") 
  }

  useEffect(() => {
    const emojis = document.querySelectorAll(".emoji-list button")
    emojis.forEach(emoji => emoji.addEventListener("click", handleEmojiClick))
  }, [])

  useEffect(() => {
    socket.on("emojireactresponse", emojiData => {
      if (emojiData?.id !== obj?._id) {
        emojii(emojiData?.emojiIndex, emojiData?.Firstname) 
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  const emojiArray = ["👍","😄","❤️","👏"]

  return (

      <div className="emoji-list"   style={{
        top: stylex?.coords?.top || "90%",
        left: stylex?.coords?.left || "40%"
      }}>
        <ul>
          {emojiArray.map((item, index) => (
            <li key={index}>
              <button className={index}>{item}</button>
            </li>
          ))}
        </ul>
      </div>
    
  )
}

export default FloatingEmoji
