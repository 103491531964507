import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/user.d1a7045e.png"
import maleAvatar from "../../../assets/images/users/male-avatar.png"
import femaleAvatar from "../../../assets/images/users/female-avatar.png"
import othersAvatar from "../../../assets/images/users/others-avatar.png"

const ProfileMenu = props => {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [avatar,setAvatar] = useState(obj?.ProfileImg === "" ? user1:obj?.ProfileImg)

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(""+obj.Firstname ? obj.Firstname :" "+" "+obj.Lastname?obj.Lastname:" ")
        if(obj?.SelectedAvatar==="maleAvatar")
          setAvatar(maleAvatar)
        else if(obj?.SelectedAvatar==="femaleAvatar")
          setAvatar(femaleAvatar)
        else if(obj?.SelectedAvatar==="othersAvatar")
          setAvatar(othersAvatar)
        console.log("obj?.SelectedAvatar", obj?.SelectedAvatar)
      }
       else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  async function postData2(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  useEffect(() => {
    if(!localStorage.getItem("authImg")){
      // alert("no")
      setTimeout(() => {
      postData2(process.env.REACT_APP_ADMIN + "/updateProfile/getprof", {
        Email: obj?.Email,
      }).then(data => {
        // console.log(data) // JSON data parsed by `data.json()` call
        if (data?.ProfileImg) {
          setAvatar(data?.ProfileImg)
          localStorage.setItem("authImg", data?.ProfileImg)
        }
      })
    }, 5000);
  
    }else{
      setTimeout(() => {
      const img = localStorage.getItem("authImg")
      img && img!="" && img!="undefined" && img!="null" ?  setAvatar(localStorage.getItem("authImg")) : null
    }, 2000);
      // console.log("ib")
    }
  }, [])
  
 
  const handleProfileClick = () => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const Email = authUser?.Email;
    const Firstname = authUser?.Firstname;
    const ProfileImg = localStorage.getItem("authImg");
    const activateuploadBtn= true;

    // Redirect to /attendeeprofile and pass data as state
    props.history.push({
      pathname: "/profile-builder",
      state: {
        Email,
        Firstname,
        ProfileImg,
        activateuploadBtn
      },
    });
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
          loading="lazy"
            className="rounded-circle header-profile-user"
            src={avatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1 ">{username}</span>
          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span> */}
          {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block "/> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile-builder" className="newyellow" onClick={handleProfileClick}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem >
          <DropdownItem tag="a" href="/logout" className="newyellow">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span className="newyellow">{props.t("Logout")}</span>
          </DropdownItem >
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
