import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { openModal, openRC, openMS, openAnn, openAnn2 } from "store/actions"
import AgendaModal from "../../pages/UIcomponents/AgendaModal"

// import 'bootstrap/dist/css/bootstrap.min.css';

import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Modal,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Media,
  Row,
  Col,
} from "reactstrap"
import classnames from "classnames"
import parse from "html-react-parser"
// import Alldata from "../../assets/localdata.json"

var tawklink = ""

const Alldata = JSON.parse(localStorage.getItem("dataJ"))
let agendalink = ""
const SidebarContent = props => {
  const [modalQAVisible, setModalQAVisible] = useState(false)
  const [modalPrefernceVisible, setModalPreferenceVisible] = useState(false)

  const obj = JSON.parse(localStorage.getItem("authUser"))
  const { modal, onOpenModal } = props
  const { rc, onOpenRC } = props
  const { ms, onOpenMS } = props
  const { ann, onOpenAnn } = props
  const { ann2, onOpenAnn2 } = props
  // https://mosaic-dxc-71185bc6afc5.herokuapp.com

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  const [ActiveTab, setActiveTab] = useState(Alldata.data.agenda.data.day1.name)
  const [helprc, onhelpOpenRC] = useState(false)
  const [agendaModalOpen, setAgendaModalOpen] = useState(false)

  const toggleAgendaModal = () => {
    setAgendaModalOpen(!agendaModalOpen)
  }
  const toggleCustom = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [rs, setRS] = useState(false)
  const togglers = () => {
    setRS(!rs)
  }

  const togglePrefernceModal = () => {
    setModalPreferenceVisible(!modalPrefernceVisible)
  }

  const toggleQAModal = () => {
    setModalQAVisible(!modalQAVisible)
  }
  const [rcc, setRCC] = useState("")
  return (
    <React.Fragment>
      <AgendaModal isOpen={agendaModalOpen} toggle={toggleAgendaModal} />

      <Modal
        isOpen={rs}
        toggle={togglers}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Opening Soon!</h5>
          <button
            type="button"
            onClick={togglers}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ color: "#00A982" }}>
          {rcc}
        </div>
      </Modal>

      <Modal size="xl" isOpen={modal} toggle={onOpenModal} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Agenda
          </h5>
          <button
            onClick={onOpenModal}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* {props.data.areas.map((item, i) => (
                <>
                  {(() => {
                    switch (item.linktype) {
                      case "agendatypelist": {
                        <div style={{ display: "none" }}>
                          {item.url ? agendalink = item.url : agendalink = ""}
                        </div>
                      }
                    }
                  })()}
                </>
              ))} */}
          {/* <iframe
                id={Alldata.data.lobby.data.areas[3].name}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                  encodeURIComponent(agendalink)
                  +
                  "?alt=media"
                }
                height="550px"
                width="100%"
              ></iframe> */}
          <Card>
            <CardBody>
              {/* <CardTitle className="h4">Agenda</CardTitle> */}
              {/* <p className="card-title-desc">Example of custom tabs</p> */}

              <Nav tabs className="nav-tabs-custom nav-justified">
                {Object.keys(Alldata.data.agenda.data).map((day, idx) => (
                  <NavItem key={idx}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active:
                          ActiveTab === Alldata.data.agenda.data[day].name,
                      })}
                      onClick={() => {
                        toggleCustom(Alldata.data.agenda.data[day].name)
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="mdi mdi-apple-airplay"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {Alldata.data.agenda.data[day].name}
                      </span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={ActiveTab} className="p-3 text-muted">
                {Object.keys(Alldata.data.agenda.data).map((day, idx) => (
                  <TabPane key={idx} tabId={Alldata.data.agenda.data[day].name}>
                    {Alldata.data.agenda.data[day].data.map((cardo, idc) => {
                      return (
                        <Row key={idc}>
                          <Col sm="12">
                            <Card>
                              <CardBody>
                                <Media>
                                  <div className="me-3">
                                    {/* <img
                              src={avatar}
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail"
                            /> */}
                                  </div>
                                  <Media body className="align-self-center">
                                    {cardo.redirect && (
                                      <a href={cardo.redirect}>
                                        <button
                                          className="btn btn-danger"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                          }}
                                        >
                                          {(cardo.btnText && cardo.btnText) ||
                                            "Join"}
                                        </button>
                                      </a>
                                    )}

                                    <div className="text-muted">
                                      <h5>{cardo.title}</h5>
                                      <h6>{cardo.time}</h6>
                                      {cardo.des && (
                                        <p className="mb-1">
                                          {parse("" + cardo.des)}
                                        </p>
                                      )}
                                    </div>
                                  </Media>
                                </Media>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      )
                    })}
                  </TabPane>
                ))}
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </Modal>

      <Modal
        isOpen={helprc}
        toggle={() => {
          onhelpOpenRC(!helprc)
        }}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Help Desk</h5>
          <button
            type="button"
            onClick={() => {
              onhelpOpenRC(!helprc)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {Alldata.data.lobby.data.areas.map((item, i) =>
            (() => {
              switch (item.id) {
                case "help": {
                  ;<div style={{ display: "none" }}>
                    {item.url ? (tawklink = item.url) : (tawklink = "")}
                  </div>
                }
              }
            })()
          )}
          <iframe
            height="450px"
            width="100%"
            title="test"
            className="embed-responsive-item"
            src={encodeURI(tawklink)}
          />
        </div>
      </Modal>

      <Modal
        size="lg"
        isOpen={modalQAVisible}
        toggle={toggleQAModal}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Mosaic Wall</h5>
          <button
            type="button"
            onClick={() => {
              setModalQAVisible(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <iframe
            height="500px"
            width="100%"
            title="test"
            className="embed-responsive-item"
            src={`https://mosaic-dxc-71185bc6afc5.herokuapp.com/?email=${obj?.Email}&name=${obj?.Firstname}`}
          />
        </div>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalPrefernceVisible}
        toggle={togglePrefernceModal}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Photo Gallery</h5>
          <button
            type="button"
            onClick={() => {
              setModalPreferenceVisible(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <iframe
            height="475px"
            width="100%"
            title="test"
            allowFullScreen
            className="embed-responsive-item"
            src="https://eventphotobooth.nyuctech.in/dashboard/feed"
          />
        </div>
      </Modal>

      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("EASY NAVIGATION")} </li>

            <li>
              <Link to="/page/lobby" className="">
                <i className="bx bx-home-circle "></i>
                <span>{props.t("Home")}</span>
              </Link>
            </li>

            <li
              onClick={() => {
                onhelpOpenRC(!helprc)
              }}
            >
              <a>
                <i className="mdi mdi-help-circle-outline "></i>
                <span>{props.t("Help Desk")}</span>
              </a>
            </li>

            {/* <li onClick={()=>{onhelpOpenRC(!helprc)}}>
              <a>
                <i className="mdi mdi-help-circle-outline "></i>
                <span>{props.t("Help Desk")}</span>
              </a>
            </li> */}
            {/* <li>
            <Link to="/page/helpdesk" className="">
                <i className="mdi mdi-help-circle-outline "></i>
                <span>{props.t("Help Desk")}</span>
                </Link>
            </li> */}
            {/* {Alldata.data.exhibitionarea ? 
            Alldata.data.exhibitionarea.edata.restricted
            ? <li onClick={()=>{setRCC(Alldata.data.exhibitionarea.edata.restrictdata.text);togglers()}}>
              <a>
              <i className="bx bx-book-add"></i>
                <span>{props.t("Exhibition Hall")}</span>
              </a>
            </li>           
            : <li>
              <Link to="/page/exhibitionarea" className="">
              <i className="bx bx-book-add"></i>
                <span>{props.t("Exhibition Hall")}</span>
              </Link>
            </li>
            :
            null
            } */}
            {/* <li>
              <Link to="/speakers" className="">
                <i >
                {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </i>
                <span>{props.t("Judges Profile")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/page/author" className="">
              <i className="bx bx-star"></i>
              <span>{props.t("Author's Corner")}</span>
              </Link>
            </li> */}

            <li className="menu-title">{props.t("SESSIONS")} </li>

            <li>
              <Link to="/page/auditorium" className="">
                <i class="bx bxl-audible"></i>
                <span>{props.t("Auditorium")}</span>
              </Link>
            </li>

            <li
              onClick={async () => {
                const areas = Alldata.data.lobby.data.areas
                const pdfAgendaUrls = areas
                  .filter(area => area?.linktype === "pdfAgenda")
                  .map(area => area.url)
                await loadPDF(pdfAgendaUrls[0])
                onOpenAnn2()
              }}
            >
              <a>
                <i class="bx bxs-book-content"></i>
                <span>{props.t("Agenda")}</span>
              </a>
            </li>
            {/* agenda */}
            {/* <li onClick={onOpenModal}>
              <Link >
                <i className="bx bx-calendar-event "></i>
                <span>{props.t("Agenda")}</span>
              </Link>
            </li> */}

            {/* <li className="menu-title">{props.t("Engagement")} </li> */}

            {/* another agenda */}
            {/* <li onClick={toggleAgendaModal}>
              <Link >
              <a>
                <i className="bx bx-calendar-event "></i>
                <span>{props.t("Agenda")}</span>
              </a>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/page/speakers" className="">
                <i >
                {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </i>
                <span>{props.t("Speakers")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/page/sponsors" className="">
              <i className="bx bx-star"></i>
              <span>{props.t("Sponsors")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/page/auditorium" className="">
                <i className="mdi mdi-apple-airplay "></i>
                <span className="badge rounded-pill bg-info float-end">
                  
                </span>
                <span>{props.t("Auditorium")}</span>
              </Link> */}
            {/* <ul className="sub-menu" aria-expanded="false">
              <li>
              <Link to="/page/audi1" className="">
                <i className="mdi mdi-apple-airplay newcol"></i>
                <span>{props.t("Auditorium-1")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi2" className="">
                <i className="mdi mdi-apple-airplay newcol"></i>
                <span>{props.t("Auditorium-2")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi3" className="">
                <i className="mdi mdi-apple-airplay newcol"></i>
                <span>{props.t("Auditorium-3")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi4" className="">
                <i className="mdi mdi-apple-airplay newcol"></i>
                <span>{props.t("Auditorium-4")}</span>
              </Link>
            </li>
              </ul> */}
            {/* </li> */}

            {/* <li>
              <Link to="/#" className="">
                <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Auditorium-1")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi2" className="">
                <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Auditorium-2")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi3" className="">
                <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Auditorium-3")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/audi4" className="">
                <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Auditorium-4")}</span>
              </Link>
            </li> 
            */}

            {/* <li className="menu-title">{props.t("INFORMATION")} </li> */}
            <li>
             <a href={`https://arpb.dxcutsav2024.com/?email=${obj?.Email}&name=${obj?.Firstname}`} target="_blank" className="">
              <i className="bx bx-camera"></i>
                <span>{props.t("AR Photobooth")}</span>
              </a>
            </li>

            {/* <li>
              <Link to="/page/arphotobooth" className="">
                <i className="bx bx-camera"></i>
                <span>{props.t("AR Photobooth")}</span>
              </Link>
            </li> */}

            {/* mosaic here */}
            <li>
             <a href={`https://mosaic.dxcutsav2024.com/`} target="_blank" className="">
              <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("Mosaic Wall")}</span>
              </a>
            </li>
             {/* <li onClick={toggleQAModal}>
              <a>
              <i className="mdi mdi-apple-airplay"></i>
              <span>{props.t("Mosaic Wall")}</span>
              </a>
            </li> */}

            {/* {Alldata.data.mosaic ? (
              Alldata.data.mosaic.edata.restricted ? (
                <li
                  onClick={() => {
                    setRCC(Alldata.data.mosaic.edata.restrictdata.text)
                    togglers()
                  }}
                >
                  <a>
                    <i className="bx bx-camera"></i>
                    <span>{props.t("Photo Mosaic")}</span>
                  </a>
                </li>
              ) : (
                <li
                  onClick={() => {
                    onOpenMS()
                  }}
                >
                  <a>
                    <i className="bx bx-camera"></i>
                    <span>{props.t("Photo Mosaic")}</span>
                  </a>
                </li>
              )
            ) : null} */}

            <li>
              <Link to="/page/funzone" className="">
                <i className="bx bx-shape-square"></i>
                <span>{props.t("Fun Zone")}</span>
              </Link>
            </li>
            <li>
              <Link to="/activityfeed" className="">
                <i className="bx bx-star"></i>
                <span>{props.t("DXC Got talent")}</span>
              </Link>
            </li>
            <li>
              <Link to="/page/outreach" className="">
                <i className="bx bx-book-add"></i>
                <span>{props.t("DXC Utsav Outreach")}</span>
              </Link>
            </li>

            {/* <li>
             <a href={`https://pb.dxcutsav2024.com/?email=${obj?.Email}&name=${obj?.Firstname}`} target="_blank" className="">
              <i className="mdi mdi-apple-airplay"></i>
                <span>{props.t("DXC Utsav Outreach")}</span>
              </a>
            </li> */}

            

            {/* <li>
              <Link to="/page/eventgallery" className="">
                <i className="bx bxs-videos"></i>
                <span>{props.t("Event Gallery")}</span>
              </Link>
            </li> */}

            <li onClick={onOpenRC}>
              <a>
                <i className="bx bxs-videos"></i>
                <span>{props.t("Event Gallery")}</span>
              </a>
            </li>

            {/* pdf agenda */}

            {/* <li>
              <Link to="/page/agenda123" className="">
              <i class="bx bxs-book-content"></i>
              <span>{props.t("Agenda")}</span>
              </Link>
            </li> */}

            {/* <li onClick={togglePrefernceModal}>
              <Link to="/page/lobby" className="">
                <i class="bx bx-food-menu"></i>
                <span>{props.t("Agenda")}</span>
              </Link>
            </li>

            */}

            {/* <li>
              <Link to="/page/photogallery" className="">
              
              <i className='bx bx-photo-album'></i>
              <span>{props.t("Photo Gallery")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/page/comingsoon" className="">
              <i className="bx bx-star"></i>
              <span>{props.t("Video Gallery")}</span>
              </Link>
            </li>    */}

            {/* <li>
              <Link to="/page/FAQs" className="">
              
              <i className="bx bx-file"></i>
              <span>{props.t("FAQs")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/grantitude" className="">
              <i className="bx bx-pencil"></i>
              <span>{props.t("GRANTitude")}</span>
              </Link>
            </li>        */}

            {/* {Alldata.data.networkingzone ? 
            Alldata.data.networkingzone.edata.restricted
            ? <li onClick={()=>{setRCC(Alldata.data.networkingzone.edata.restrictdata.text);togglers()}}>
              <a>
              <i className="bx bx-chat"></i>
              <span>{props.t("Networking Zone")}</span>
              </a>
            </li>           
            : <li>
              <Link to="/page/networkingzone" className="">
              <i className="bx bx-chat"></i>
              <span>{props.t("Networking Zone")}</span>
              </Link>
            </li>
            :
            null
            }  */}

            {/* <li>
              <Link to="/#" className="">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </i>
                <span>{props.t("Photo Mosaic")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/page/pageprogram" className="">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </i>
                <span>{props.t("Programs")}</span>
              </Link>
            </li> */}

            <li className="menu-title">{props.t("INFORMATION")} </li>

            {/* <li>
              <Link to="/leaderboard" className="">
                <i className="bx bx-dock-top"></i>
                <span>{props.t("LeaderBoard")}</span>
              </Link>
            </li> */}
            <li onClick={onOpenAnn}>
              <a>
                <i className="bx bxs-bell-ring"></i>
                <span>{props.t("Push Notification")}</span>
              </a>
            </li>
            {/* <li>
              <Link to="/page/bcard" className="">
                <i className="bx bx-dock-top"></i>
                <span>{props.t("Business Cards")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/page/meetings" className="">
                <i className="bx bx-layout"></i>
                <span>{props.t("Meetings")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/page/photozone" className="">
                <i className="bx bx-camera"></i>
                <span>{props.t("Photo Zone")}</span>
              </Link>
            </li> */}
            {/* photobhooth */}
            {/* <li>
            <a href={process.env.REACT_APP_PhotoBooth+"?email="+obj.Email+"&name="+obj.Firstname} target="_blank" className="">
              <i className="bx bx-camera"></i>
                <span>{props.t("Photo Booth")}</span>
              </a>
            </li> */}

            {/* <li>
              <a href={"https://master.daplqztvfoa6c.amplifyapp.com/?id="+obj.Email+"|"+obj.Firstname} target="_blank" className="">
              <Link to="/page/games-img" className="">
             
                <i className="bx bx-webcam"></i>
                <span>{props.t("1:1 Interaction")}</span>
              </Link>
            </li>   */}

            {/* <li>
            <a href={"https://"+window.location.host+"/activityfeed"} className="">
            <Link to="/activityfeed" className="">
                <i className="bx bx-been-here"></i>
                <span>{props.t("AR Invite")}</span>
              </Link>
              </a>
            </li> */}

            {/* <li>
              <Link to="/page/arinvite" className="">
              <i className='bx bxl-mastercard'></i>
              <span>{props.t("AR Invite")}</span>
              </Link>
            </li>  */}

            {/* <li>
              <Link to="/page/socialwall" className="">
                <i className="bx bx-been-here"></i>
                <span>{props.t("Social Media Wall")}</span>
              </Link>
            </li> */}
            {/* {Alldata.data.grantitude ? (
              <li>
                <Link to="/page/grantitude" className="">
                  <i className="bx bx-pencil"></i>
                  <span>{props.t("Gratitude Card")}</span>
                </Link>
              </li>
            ) : (
              <li>
                <Link to="/grantitude" className="">
                  <i className="bx bx-pencil"></i>
                  <span>{props.t("Gratitude Card")}</span>
                </Link>
              </li>
            )} */}

            {/* <li className="menu-title">{props.t("Engagement")} </li> */}

            {/* <li onClick={togglePrefernceModal}>
              <a>
                <i className="bx bxs-photo-album "></i>
                <span>{props.t("Photo Gallery")}</span>
              </a>
            </li> */}

            {/* <li >
              <a href="https://facialrecognition.algomage.com/facerec/omsi-15th-annual-day" target="_blank">
              <i className="bx bxs-photo-album "></i>
              <span>{props.t("Photo Gallery")}</span>
              </a>
            </li> */}

            {/* <li>
              <Link to="/page/photogallery" className="">
              <i className="bx bxs-photo-album "></i>
                <span>{props.t("Photo Gallery")}</span>
              </Link>
            </li> */}
            {/* <li onClick={onOpenRC}>
              <a>
                <i className="bx bxs-videos"></i>
                <span>{props.t("Video Gallery")}</span>
              </a>
            </li> */}

            {/* <li>
              <Link to="/page/videogallery" className="">
              <i className="bx bxs-videos"></i>
                <span>{props.t("Video Gallery")}</span>
              </Link>
            </li> */}

            {/* <li >
              <a href="https://player.vimeo.com/video/1014311963?" target="_blank">
              <i className="bx bxs-videos"></i>
                <span>{props.t("Video Gallery")}</span>
              </a>
            </li> */}

            {/* <li onClick={toggleQAModal}>
              <a>
              <i className="bx bx-dice-6"></i>
                <span>{props.t("Feedback Polls")}</span>
              </a>
            </li> */}
            {/* <li>
              <a
                href={
                  process.env.REACT_APP_PhotoBooth +
                  "?email=" +
                  obj.Email +
                  "&name=" +
                  obj.Firstname
                }
                target="_blank"
                className=""
              >
                <i className="bx bx-camera"></i>
                <span>{props.t("Selfie Booth")}</span>
              </a>
            </li> */}

            {/* <li>
              <a target="_blank" href={
                        "http://127.0.0.1:5501/?id="+
                        obj.Email +
                        "|" +
                        obj.Firstname +
                        "|" +
                        "&admin=" +
                        btoa(
                          process.env.REACT_APP_ADMIN + "/submitscore"
                        ) +
                        "&platform=" +
                        btoa(window.location.origin + "/leaderboard")
                      }
              >
                <i class="bx bx-dice-6"></i>
                <span>{props.t("Neon Brick")}</span>
              </a>
            </li> */}

            {/* <li>
              <Link to="/page/graffiti" className="">
                <i className="bx bx-pencil"></i>
                <span>{props.t("Graffiti Wall")}</span>
              </Link>
            </li>

            <li>
              <Link to="/page/quiz" className="">
                <i className="bx bx-dice-6"></i>
                <span>{props.t("Quiz")}</span>
              </Link>
            </li>

            <li>
              <Link to="/page/photobooth" className="">
                <i className="bx bx-camera"></i>
                <span>{props.t("Selfie")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/networking" className="">
              <i className="bx bx-pencil"></i>
              <span>{props.t("One on One Chat")}</span>
              </Link>
            </li>
            <li>
              <Link to="/activityfeed" className="">
              <i className="bx bx-been-here"></i>
                <span>{props.t("Group Chat")}</span>
              </Link>
            </li>

            <li onClick={toggleQAModal}>
            <a>
              <i className='bx bx-git-pull-request'></i>
              <span>{props.t("Live Q&A")}</span>
            </a>
            </li> */}
            {/* <li onClick={togglePrefernceModal}>
              <Link to="/page/lobby" className="">
              <i class='bx bx-food-menu'></i>
              <span>{props.t("Dietary Preferences")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/preferences" className="">
              
              <i className="bx bx-food-menu"></i>
              <span>{props.t("Customer Preferences")}</span>
              </Link>
            </li>
            */}
            {/* <li>
              <Link to="/page/Speakers" className="">
              
              <i class='bx bx-user' ></i>
              <span>{props.t("Speaker profile")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/weather" className="">
              
              <i className="bx bx-cloud"></i>
              <span>{props.t("Weather")}</span>
              </Link>
            </li> */}

            {/* <li onClick={onOpenAnn}>
              <a>
                <i className="bx bxs-bell-ring"></i>
                <span>{props.t("Notification")}</span>
              </a>
            </li> */}
            {/* <li onClick={onOpenRC}> */}
            {/* <li >
              <Link to="/page/bookmark" className="">
                <i className="bx bx-bookmark newcol"></i>
                <span>{props.t("Bookmark")}</span>
              </Link>
            </li> */}
            {/* <li >
              <Link to="/profile" className="">
                <i className="bx bx-barcode newcol"></i>
                <span>{props.t("QR code")}</span>
              </Link>
            </li>
            <li >
              <Link to="/profile" className="">
                <i className="bx bx-user newcol"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li> */}
            {/* <li >
              <Link to="/page/pdflib" className="">
                <i className="bx bx-file newcol"></i>
                <span>{props.t("Pdf Library")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  modal: PropTypes.any,
  rc: PropTypes.any,
  ann2: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
  onOpenModal: PropTypes.any,
  onOpenRC: PropTypes.any,
  onOpenAnn: PropTypes.any,
  onOpenAnn2: PropTypes.any,
}

const mapStateToProps = ({ agenda }) => ({
  modal: agenda.modal,
  rc: agenda.rc,
  ms: agenda.ms,
  ann: agenda.ann,
  ann2: agenda.ann2,
})

const mapDispatchToProps = dispatch => ({
  onOpenModal: () => dispatch(openModal()),
  onOpenRC: () => dispatch(openRC()),
  onOpenMS: () => dispatch(openMS()),
  onOpenAnn: () => dispatch(openAnn()),
  onOpenAnn2: () => dispatch(openAnn2()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SidebarContent)))
