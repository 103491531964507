import React from "react"
import PropTypes from "prop-types"

const ActivityFeedComment = props => {
  return (
    <>
      <div className="media py-3">
        <div className="avatar-xs me-3">
          <div className="avatar-title rounded-circle text-light">{props.Firstname.charAt(0)}</div>
        </div>
        <div className="media-body">
          <h5 className="font-size-14 mb-1 ">
            {props.Firstname}
            {/* <small className="text-muted float-end">1 hr Ago</small> */}
          </h5>
          <p className="">
            {props.UserComment}
          </p>
        </div>
      </div>
    </>
  )
}

ActivityFeedComment.propTypes = {
  Firstname:String,
  UserComment:String
}

export default ActivityFeedComment
