import ActivityFeedCard from "pages/UIcomponents/ActivityFeedCard"
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import {
  Card,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
import Webcam from "react-webcam"
import "./Act.css"

const ActivityFeed = props => {
  let obj = JSON.parse(localStorage.getItem("authUser"))
  const [userpostArray, setuserpostArray] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [specificFeed, setSpecificFeed] = useState()
  const [fileFlag, setfileFlag] = useState(false)
  const [userCaption, setuserCaption] = useState()
  const [selectedImage, setselectedImage] = useState(false)
  
  const [userOption, setuserOption] = useState()
  let [userpostpage, setuserpostpage] = useState(0)
  const [modal, setmodal] = useState(false)
  const [modalrule, setmodalrule] = useState(false)
  const toggle = () => setmodalrule(!modalrule)
  const loader = document.querySelector(".loader")

  const [openCamera, setopencamera] = useState(false)
  const webcamRef = React.useRef(null)

  function handleUserPostSubmit() {
    if (selectedFiles && userCaption && userOption) {
      UpDropFile(selectedFiles)
    } else {
      if (!selectedFiles) {
        alert("Select an Image or Video to upload")
      }
    }
  }

  async function base64ToBlob(base64, mimeType) {
    const byteString = atob(base64.split(",")[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeType })
  }

  async function handleAcceptedFiles(files) {
    if (
      files[0].type.split("/")[0] == "image" ||
      files[0].type.split("/")[0] == "video"
    ) {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setselectedFiles(files)
      setfileFlag(true)
    } else {
      alert("Please Select only image or video files")
    }
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  async function UpDropFile(files) {
    console.log(obj)
    console.log(files)

    document.getElementById("my-btn").disabled = true
    document.getElementById("upload-loader").style.display = "block"
    const resp = await uploadActivity(!openCamera ? files[0] : files)
    console.log(files)
    console.log("resp: ", resp)
    if (resp != null) {
      fetch(process.env.REACT_APP_ADMIN + "/userpost/", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          Firstname: obj?.Firstname,
          Email: obj?.Email,
          UserPostUrl: resp,
          UserPostType: files[0]?.type?.split("/")[0] || "image",
          UserPostCaption: userCaption,
          UserOption: userOption,
        }), // body data type must match "Content-Type" header
      }).then(data => {
        console.log("File upload success", data)
        document.getElementById("upload-loader").style.display = "none"
        document.getElementById("my-btn").disabled = false
        document.querySelector("#cap").value = ""
        setmodal(!modal)
        callActivityApi("Post", 50)
        alert("Post Uploaded succesfully")
        // document.querySelector("#upFile").value = ""
        fetchalluserposts(0, false)
      })
    } else {
      alert("file not uploaded", resp)
    }
  }
  async function fetchalluserposts(event, ev) {
    setuserpostpage(event)
    let response1 = await fetch(
      process.env.REACT_APP_ADMIN + "/userpost/getuserposts",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ postPage: event }), // body data type must match "Content-Type" header
      }
    )
      .then(response1 => response1.json())
      .then(data => {
        if (data.length < 5) {
          //if posts less than 5 it has come near end of number of posts present
          if (ev) {
            setuserpostArray(userpostArray => [...userpostArray, ...data])
          } else {
            setuserpostArray(data)
          }
          $("#load-more").attr("disabled", true)
          $("#load-div").html("End of Posts!!")
        } else {
          if (ev) {
            setuserpostArray(userpostArray => [...userpostArray, ...data])
          } else {
            setuserpostArray(data)
          }
        }
      })
  }
  const callActivityApi = (gname, points) => {
    let activityArr = localStorage.getItem("activityArr")
    if (!activityArr) {
      activityArr = []
    } else {
      activityArr = JSON.parse(activityArr)
    }
    let currentPageArr = activityArr.filter(elem => elem == gname)

    //commented if to give multilple post points
    // if(!currentPageArr.length){
    postData(process.env.REACT_APP_ADMIN + "/submitscore", {
      gname: gname,
      id: obj.Email + "|" + obj.Firstname + "|" + obj.Teamname,
      score: points,
    }).then(data => {
      // console.log(data); // JSON data parsed by `data.json()` call
      // if(data[0]=="success"){}//for successfully adding points
      activityArr.push(gname)
      localStorage.setItem("activityArr", JSON.stringify(activityArr))
    })
    // }

    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }
  }
  // const uploadFile = async () => {
  //   if (
  //     !document.querySelector("#cap").value ||
  //     !document.querySelector("#upFile").value
  //   ) {
  //     console.log("value in capiton  :", document.querySelector("#cap").value)
  //     console.log("value in upFile  :", document.querySelector("#upFile").value)
  //   } else {
  //     document.getElementById("my-btn").disabled = true
  //     document.getElementById("upload-loader").style.display = "inline-block"
  //     const resp = await uploadActivity(
  //       document.querySelector("#upFile").files[0]
  //     )
  //     console.log("resp: ", resp)
  //     if (resp != null) {
  //       fetch(process.env.REACT_APP_ADMIN + "/userpost/", {
  //         method: "POST", // *GET, POST, PUT, DELETE, etc.
  //         mode: "cors", // no-cors, *cors, same-origin
  //         cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //         credentials: "same-origin", // include, *same-origin, omit
  //         headers: {
  //           "Content-Type": "application/json",
  //           // 'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         redirect: "follow", // manual, *follow, error
  //         referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //         body: JSON.stringify({
  //           Firstname: obj1.Firstname,
  //           Email: obj1.Email,
  //           UserPostUrl: resp,
  //           UserPostType: document
  //             .querySelector("#upFile")
  //             .files[0].type.split("/")[0],
  //           UserPostCaption: document.querySelector("#cap").value,
  //         }), // body data type must match "Content-Type" header
  //       }).then(data => {
  //         console.log("File upload success", data)
  //         document.getElementById("upload-loader").style.display = "none"
  //         document.getElementById("my-btn").disabled = false
  //         document.querySelector("#cap").value = ""
  //         document.querySelector("#upFile").value = ""
  //         fetchalluserposts(0, false)
  //       })
  //     } else {
  //       alert("file not uploaded", resp)
  //     }
  //     console.log("resp: ")
  //   }
  // }

  const handleCategory = e => {
    // if (
    //   e.target.value === "Theft of Picasso (Art)" ||
    //   e.target.value === "Capture the essence (Photography)" ||
    //   e.target.value === "Doodle Duel (Doodling)"
    // ) {
    //   setopencamera(true)
    // } else {
    //   setopencamera(false)
    // }
    setuserOption(e.target.value)
  }

  const capture = React.useCallback(
    async e => {
      e.preventDefault()
      const imageSrc = webcamRef.current.getScreenshot()
      const mimeType = "image/jpeg"
      const data = await base64ToBlob(imageSrc, mimeType)
      setselectedFiles(data)
      setselectedImage(imageSrc);
      setfileFlag(true)
    },
    [webcamRef]
  )

  const callApi = (type, Userposturl) => {
    // e.preventDefault()
    console.log("type", type, "Userposturl", Userposturl)
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }

    postData(process.env.REACT_APP_ADMIN + "/userpost/" + type, {
      cardId: Userposturl,
    }).then(data => {
      setSpecificFeed(data);
      // localStorage.setItem("authUser", JSON.stringify(data))
    })

 
  }

 
  useEffect(() => {
    loader.classList.add("loader--hide")
    fetchalluserposts(0)
    //   fetch(process.env.REACT_APP_ADMIN + "/userpost/getuserposts")
    //     .then(response => response.json())
    //     .then(async data => {
    //         let newData = await data.map(function (obj, Index) {
    //             obj.sno = Index + 1
    //             return obj
    //         })
    //         setuserpostArray(newData)
    //     })
  }, [])


  useEffect(() => {
    let url = window.location.search
  let temp1
    setTimeout(() => {
      let url = window.location.search
    if (url) {
      temp1 = url.split("?post=")[1]
      callApi("getSpecificUserPosts", temp1)
    }
    }, 1000);
  }, [])

  
  const retakePhoto = (e) => {
    e.preventDefault();
   setselectedImage("") // Reset the selected image to retake the photo
  };

  // function Example({ direction, ...args }) {
  //   const [dropdownOpen, setDropdownOpen] = useState(false);

  //   const toggle = () => setDropdownOpen((prevState) => !prevState);
  // }

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal)
          // setmodalrule(!modalrule)
          setfileFlag(false)
          setselectedFiles([]) //clear files selection on modal close
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal)
            setfileFlag(false)
            setselectedFiles([]) //clear files selection on modal close
          }}
        >
          Add Photo/Video
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Form>
              <FormGroup className="mb-4" row>
                <Label
                  style={{ color: "#ffffff" }}
                  htmlFor="cap"
                  className="col-form-label col-lg-2"
                >
                  Add Caption
                </Label>
                <Col lg="10">
                  <Input
                    id="cap"
                    name=""
                    type="textarea"
                    className="form-control"
                    placeholder="Type Your caption/comments here..."
                    onChange={e => {
                      setuserCaption(e.target.value)
                    }}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Label style={{ color: "#ffffff" }} for="select">
                  Categories for DXC GOT Talent
                </Label>
                <Input
                  onChange={e => {
                    handleCategory(e)
                  }}
                  id="select"
                  name="select"
                  type="select"
                  style={{ marginBottom: "2em" }}
                >
                 <option hidden>Select Category</option>
                  
                 <option value="Jacksons Shoes (Dance)">Jacksons Shoes (Dance)</option>
                 <option value="Hit the Notes (Music)">Hit the Notes (Music)</option>
                 <option value="Theatrics (Open Stage)">Theatrics (Open Stage)</option>
                 <option value="Theft of Picasso (Art)">Theft of Picasso (Art)</option>
                 <option value="Capture the essence (Photography)">Capture the essence (Photography)</option>
                 <option value="Doodle Duel (Doodling)">Doodle Duel (Doodling)</option>






                </Input>
              </FormGroup>

              {openCamera ? (
                selectedImage ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={selectedImage} alt="Captured" />
                    <button
                      className="btn btn-primary mt-3"
                      onClick={retakePhoto}
                    >
                      Retake Photo
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                    />
                    <button className="btn btn-primary mt-3" onClick={capture}>
                      Capture photo
                    </button>
                  </div>
                )
              ) : (
                <Dropzone
                  onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}

              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f?.name}
                              src={f?.preview}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f?.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f?.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )
                })}
              </div>
              <button
                id="my-btn"
                disabled={!(fileFlag && userCaption)}
                style={{ background: "#4F178A" }}
                onClick={e => {
                  e.preventDefault()
                  handleUserPostSubmit()
                }}
                className="btn btn-primary form-control mt-3"
              >
                Upload
              </button>
              <div id="upload-loader" style={{ display: "none" }}>
                <Spinner className="ms-2 mt-2" color="primary" />
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalrule} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>Activity Rules</ModalHeader>
        <ModalBody toggle={toggle}>
          <iframe
            id={"rules"}
            src={
              "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/dxc%20utsav%2FRules.pdf?alt=media"
            }
            height="500px"
            width="100%"
          ></iframe>
        </ModalBody>
      </Modal>

      <div className="page-content main-div" style={{overflow:"scroll"}}>
        <div className="container-fluid">
          <button
          disabled
          style={{cursor:"not-allowed"}}
            className="btn btn-feed btn-post1"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Submit your entry for DXC Got Talent
          </button>
          <Button
            className="btn-feed btn-post btn-outline-dark"
            onClick={() => {
              setmodalrule(!modalrule)
            }}
          >
            Rules
          </Button>
          <center
            className="mb-2"
            style={{
              fontSize: "large",
              color: "rgb(254,254,254)",
            }}
          >
            DXC Got Talent
          </center>
         
          {userpostArray.map((b, idx) => (
            <ActivityFeedCard
              key={idx}
              cardUsername={b.Firstname}
              cardCaption={b.Userpostcaption}
              cardType={b.Userposttype}
              cardUrl={b.Userposturl}
              cardId={b._id}
              cardLikeCount={b.Userpostlike}
              cardComments={b.Comments}
            />
          ))}
           {specificFeed && specificFeed.map((b, idx) => (
            <ActivityFeedCard
              key={idx}
              cardUsername={b.Firstname}
              cardCaption={b.Userpostcaption}
              cardType={b.Userposttype}
              cardUrl={b.Userposturl}
              cardLikeCount={b.Userpostlike}
              cardId={b._id}
              cardComments={b.Comments}
            />
          ))}
           
         
          {/* <ActivityFeedCard
                        cardUsername="Name here"
                        cardCaption="Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet"
                        cardImage="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Colt%2FHelpDesk-min.jpg?alt=media"
                        cardVideo="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Colt%2FEntry.mp4?alt=media"
                    />
                    
                    <ActivityFeedCard
                        cardUsername="Name here"
                        cardCaption="caption"
                        cardImage=""
                        cardVideo="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Colt%2FEntry.mp4?alt=media"
                    /> */}
          <center>
            <button className="btn btn-feed" id="load-more">
              <div
                id="load-div"
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "rgb(253,253,253)",
                }}
                onClick={() => {
                  fetchalluserposts((userpostpage += 1), true)
                }}
              >
                Load More
              </div>
            </button>
          </center>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ActivityFeed
