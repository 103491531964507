import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardText,
  CardHeader,
  CardBody,
  CardTitle,
  CardImg,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import { Link } from "react-router-dom"
import "./ActFeed.css"
import { parseInt } from "lodash"
import ActivityFeedComment from "./ActivityFeedComment"

const ActivityFeedCard = props => {
  const [obj, setObj] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [likedisable, setlikeDisable] = useState(false)
  const [likeCount, setlikeCount] = useState(props.cardLikeCount)
  const [unlikedisable, setunlikeDisable] = useState(false)
  const [commentsView, setCommentsView] = useState("")
  const [comment, setComment] = useState("")

  function handleUserCommentSubmit(cardUrl) {
    if (comment) {
      console.log("cmnt",comment)
      UploadComment()
      // callApi("addComment", cardUrl)
    } else {
      if (!selectedFiles) {
        alert("Type comment first")
      }
    }
  }
  // async function UploadComment() {
  // const resp=  await fetch(process.env.REACT_APP_ADMIN + "/userpost/addComment", {
  //     method: "POST", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     redirect: "follow", // manual, *follow, error
  //     referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //     body: JSON.stringify(), // body data type must match "Content-Type" header
  //   }).then(data => {
  //     console.log("FFFFFFFFFFFFFFF", data)
  //   })
  // }
  const UploadComment = () => {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }

    // postData(process.env.REACT_APP_ADMIN + "/userpost/addComment", {
    //   Firstname: obj.Firstname,
    //   Email: obj.Email,
    //   Userposturl: props.cardUrl,
    //   UserComment: comment,
    // }).then(data => {
    //   console.log(data); // JSON data parsed by `data.json()` call
    //   // setObj(data)
    //   // localStorage.setItem("authUser", JSON.stringify(data))
    // })
    var request = new XMLHttpRequest()
    request.open("POST", process.env.REACT_APP_ADMIN + "/userpost/addComment")
    request.setRequestHeader("Content-Type", "application/json")
    request.send(
      JSON.stringify({
        Firstname: obj.Firstname,
        Email: obj.Email,
        Userposturl: props.cardUrl,
        UserComment: comment,
      })
    )
  }
  const callApi = (type, Userposturl) => {
    // e.preventDefault()
    console.log("type", type, "Userposturl", Userposturl)
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }

    postData(process.env.REACT_APP_ADMIN + "/userpost/" + type, {
      Firstname: obj.Firstname,
      Email: obj.Email,
      Userposturl: props.cardUrl,
      UserComment: comment,
    }).then(data => {
      console.log(data) // JSON data parsed by `data.json()` call
      setObj(data)
      // localStorage.setItem("authUser", JSON.stringify(data))
    })

    //activity points start
    let activityArr = localStorage.getItem("activityArr")
    let activityPoints = 1
    if (!activityArr) {
      activityArr = []
    } else {
      activityArr = JSON.parse(activityArr)
    }
    let currentPageArr = activityArr.filter(elem => elem == "Like")

    if (!currentPageArr.length) {
      let obj = JSON.parse(localStorage.getItem("authUser"))
      async function ActivityApiCall(url = "", data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        return response.json() // parses JSON response into native JavaScript objects
      }
      ActivityApiCall(process.env.REACT_APP_ADMIN + "/submitscore", {
        gname: "Like",
        id: obj.Email + "|" + obj.Firstname + "|" + obj.Teamname,
        score: activityPoints,
        typeActivity: "Like",
      }).then(data => {
        // console.log(data); // JSON data parsed by `data.json()` call
        // if(data[0]=="success"){}//for successfully adding points
        activityArr.push("Post")
        localStorage.setItem("activityArr", JSON.stringify(activityArr))
      })
    }
    // activity points end
  }
 
  const scrollToPost = id => {
    const targetDiv = document.getElementById(id)
    if (targetDiv) {
      targetDiv.scrollIntoView({
        behavior: "smooth",
        block: "end",
      })
    } else {
      console.error("targetDiv not found")
    }
  }

  useEffect(() => {
    setTimeout(() => {
      let url = window.location.search

    let temp1
    if (url) {
      temp1 = url.split("?post=")[1]
        scrollToPost(temp1)
    }
    }, 1000);
    
  }, [])


  return (
    <>
      {/* <Card className="p-1 border shadow-none"> */}
      {/* <div className="p-3">
                <h5>
                    <div to="blog-details" className="text-dark">
                        {props.cardUsername}
                    </div>
                </h5>
                <p className="text-muted mb-0">{props.cardCaption}</p>
            </div>

            <div className="position-relative">
            <center>
                {console.log(props.cardImage)}
                {(props.cardType=="image")
                    ?

                    <img
                        style={{ maxHeight: "50vh", height: "50vh" }}
                        src={props.cardUrl}
                        alt=""
                        className=""
                    />
                    :
                    <iframe
                        style={{ maxHeight: "50vh", height: "50vh",maxWidth:"45vw",width:"45vw" }}
                        title="Post Image/ Video"
                        className="embed-responsive-item"
                        src={props.cardUrl}
                    />
                }

                </center>
            </div> */}

      <Card
        className="activityCard p-4"
        id={props.cardId}
        style={{ overflowY: "scroll" }}
      >
        <Row className="no-gutters">
          <Col md={6}>
            <CardBody>
              <CardTitle style={{ color: "ffffff" }}>
                {props.cardUsername}
              </CardTitle>
              <CardText style={{ color: "ffffff" }}>
                {props.cardCaption}
              </CardText>
              <CardText>
                <span
                  className="p-3"
                  // style={{position:"absolute",bottom:"0px"}}
                  id=" likeid"
                >
                  <ul className="list-inline" style={{ margin: "0px" }}>
                    <li className="list-inline-item me-3">
                      <Link to="#" className="text-muted">
                        {/* <i className="bx bxs-heart align-middle text-muted me-1 increased-heartsize"></i>{" "} */}
                        {obj.Userlikedpost &&
                        obj.Userlikedpost.includes(props.cardUrl) ? (
                          <span style={{ color: "white" }}>
                            <button
                              type="button"
                              disabled={likedisable}
                              className="btntrans"
                              onClick={() => {
                                setlikeDisable(true)
                                setunlikeDisable(false)
                                setlikeCount(parseInt(likeCount) - 1)
                                callApi("disLikeUserPost", props.cardUrl)
                              }}
                            >
                              <i
                                className="bx bxs-heart align-middle  heartColor increased-heartsize"
                                style={{ color: "red" }}
                              ></i>
                            </button>
                            {likeCount}
                            {/* <div> You & {props.cardLikeCount} others</div> */}
                          </span>
                        ) : (
                          <span style={{ color: "white" }}>
                            <button
                              type="button"
                              disabled={unlikedisable}
                              className="btntrans"
                              onClick={() => {
                                setunlikeDisable(true)
                                setlikeDisable(false)
                                setlikeCount(parseInt(likeCount) + 1)
                                callApi("addUserPostLike", props.cardUrl)
                              }}
                            >
                              <i
                                className="bx bx-heart align-middle  heartColor increased-heartsize"
                                style={{ color: "red" }}
                              ></i>
                            </button>
                            {likeCount}
                            {/* <div> {props.cardLikeCount}</div> */}
                          </span>
                        )}
                        <button
                          style={{ color: "white" }}
                          type="button"
                          className="btntrans"
                          onClick={() => {
                            setCommentsView(!commentsView)
                          }}
                        >
                          <i
                            className="bx bx-comment-dots align-middle me-1 increased-heartsize"
                            style={{ color: "white" }}
                          ></i>
                          {props.cardComments.length}
                        </button>
                        {/* post share */}
                       
                        <button
                          type="button"
                          className="btntrans"
                          onClick={() => {
                            const cardUrl =
                              window.location.origin +
                              "/activityfeed?post=" +
                              props?.cardId
                            if (cardUrl) {
                              navigator.clipboard
                                .writeText(cardUrl)
                                .then(() => {
                                  alert("Link copied to clipboard")
                                  btoa(cardUrl)
                                })
                                .catch(err => {
                                  console.error("Failed to copy URL:", err)
                                })
                            }
                          }}
                        >
                          <i
                            className="bx bxs-share-alt align-middle me-1 increased-heartsize"
                            style={{ color: "white" }}
                          ></i>
                        </button>
                      </Link>
                    </li>
                    {/* <li className="list-inline-item me-3">
                        <Link to="#" className="text-muted">
                            <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                            12 Comments
                        </Link>
                    </li> */}
                  </ul>
                  {/* <p>
                    Neque porro quisquam est, qui dolorem ipsum quia
                    dolor sit amet
                </p>

                <div>
                    <Link to="#" className="text-primary">
                        Read more{" "}
                        <i className="mdi mdi-arrow-right"></i>
                    </Link>
                </div> */}
                </span>
              </CardText>
              {commentsView && (
                <div className="commentDiv" id="style-1">
                  {commentsView &&
                    props.cardComments.map((comment, index) => {
                      return (
                        <ActivityFeedComment
                          Firstname={comment.Firstname}
                          UserComment={comment.UserComment}
                        />
                      )
                    })}
                </div>
              )}
              <Form>
                <FormGroup className="mb-4" row>
                  <Col lg="8">
                    <Input
                      id="ActCommentInput"
                      name=""
                      type="textarea"
                      className="form-control"
                      placeholder="Type Your comments here..."
                      onChange={e => {
                        setComment(e.target.value)
                      }}
                    />
                  </Col>
                  <Col lg="2">
                    <button
                      type="submit"
                      className="btn btn-feed mt-2"
                      onClick={() => {
                        handleUserCommentSubmit(props.cardUrl)
                      }}
                    >
                      Submit
                    </button>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Col>
          <Col md={6}>
            {props.cardType == "image" ? (
              <CardImg
                className=""
                src={props.cardUrl}
                alt="User Post Image"
                style={{ maxHeight: "400px" }}
              />
            ) : (
              <video
                controls
                title="Post Image/ Video"
                className="embed-responsive-item activityVideo"
                src={props.cardUrl}
              >
                <source src={props.cardUrl} type="video/mp4" />
              </video>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

ActivityFeedCard.propTypes = {
  cardUsername: PropTypes.string,
  cardCaption: PropTypes.string,
  cardType: PropTypes.string,
  cardLikeCount: PropTypes.string,
  cardUrl: PropTypes.string,
  cardText: PropTypes.string,
  cardComments: PropTypes.array,
}

export default ActivityFeedCard

