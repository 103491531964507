import React, { useState } from "react"
import PropTypes from "prop-types"

import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Notifications = (props) => {
  const [showEasing, setshowEasing] = useState("swing");//swing, linear
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");//fadeIn,fadeOut
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(500000000);
  const [extendedTimeOut, setextendedTimeOut] = useState(100000000000);

  function showToast() {
    const ele = document.getElementsByName("toastType");
    const position = document.getElementsByName("positions");
    let toastType=props.toastType
    const title = props.title;
    let message = props.body;
    
    //Close Button
    const closeButton = "checked"

    //Debug
    const debug = "checked"

    //Progressbar
    const progressBar = "checked"

    //Duplicates
    const preventDuplicates = true

    //Newest on Top
    const newestOnTop = "checked"
    //position class
    let positionClass = "toast-top-center"

    //Fetch position
    for (let p = 0; p < position.length; p++) {
      if (position[p].checked) positionClass = position[p].value
    }

    //Fetch checked Type
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) toastType = ele[i].value
    }

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: true,
      newestOnTop: newestOnTop, 
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    }


    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title)
    else if (toastType === "warning") toastr.warning(message, title)
    else if (toastType === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  return (
    <>
    {/* {showToast()} */}
    </>
  )
}

Notifications.propTypes = {
  toastType: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
}

export default Notifications