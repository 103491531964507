import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import store from "./store"
import { isMobile } from "react-device-detect"
import DeviceOrientation, { Orientation } from "react-screen-orientation"
import { Container } from "reactstrap"
import localJsonData from "./assets/localdata.json"

const loader = document.querySelector(".loader")

const showLoader = () => loader.classList.remove("loader--hide")
const hideLoader = () => loader.classList.add("loader--hide")

const allDeviceSupport = true

const renderApp = () => {
  const app = (
    <Provider store={store}>
    <BrowserRouter>
      {allDeviceSupport ? (
        <App hideLoader={hideLoader} showLoader={showLoader} />
      ) : (
        (isMobile && (window.location.pathname=="/login" || window.location.pathname=="/home" || (localStorage.getItem("authUser") ? false: window.location.pathname=="/"))) ? 
        <DeviceOrientation lockOrientation={"portrait"}>
          {/* Will only be in DOM in landscape */}
          <Orientation orientation="portrait" alwaysRender={false}>
          <Container style={{ padding: 0, position: "relative" }} fluid>
              <img
                width="100%"
                height="100%"
                alt="clickable"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/image1%2Fportrait-orientation.png?alt=media"
                }
                style={{
                  zIndex: 9999999999999999999,
                  position: "relative",
                  display: "block",
                  backgroundColor: "white",
                }}
                onLoad={() => {
                  loader.classList.add("loader--hide")
                }}
              />
            </Container>
          </Orientation>
          <Orientation orientation="landscape" alwaysRender={false}>
          <App hideLoader={hideLoader} showLoader={showLoader} />

          </Orientation>
        </DeviceOrientation>
        :
        <DeviceOrientation lockOrientation={"landscape"}>
          {/* Will only be in DOM in landscape */}
          <Orientation orientation="landscape" alwaysRender={false}>
            <App hideLoader={hideLoader} showLoader={showLoader} />
          </Orientation>
          <Orientation orientation="portrait" alwaysRender={false}>
            <Container style={{ padding: 0, position: "relative" }} fluid>
              <img
                width="100%"
                height="100%"
                alt="clickable"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/image1%2Frotate.png?alt=media"
                }
                style={{
                  zIndex: 9999999999999999999,
                  position: "relative",
                  display: "block",
                  backgroundColor: "black",
                }}
                onLoad={() => {
                  loader.classList.add("loader--hide")
                }}
              />
            </Container>
          </Orientation>
        </DeviceOrientation>
      )}
    </BrowserRouter>
  </Provider>
  )

  //

  ReactDOM.render(app, document.getElementById("root"))
}

let authUser = JSON.parse(localStorage.getItem("authUser"))

if (authUser) {
    let user = {
      Firstname: authUser.Firstname,
      EmployeeID: authUser.EmployeeID,
      Email: authUser.Email,
      Location: authUser.Location,
      PhoneNumber: authUser.PhoneNumber,
      ProfileImg: authUser.ProfileImg,
      Token: authUser.Token
        ? authUser.Token
        : localStorage.getItem("notificationToken"),
      customaction: "Login",
    }
    // loginUser(user) 
}

async function loginUser(user) {
  const adminPanel = isMobile
    ? `${process.env.REACT_APP_ADMIN}`
    : `${process.env.REACT_APP_ADMIN_LAPTOP}`;

  try {
    const response = await fetch(`${adminPanel}/login`, {
      method: "POST", 
      headers: {
        "Content-Type": "application/json", 
      },
      body: JSON.stringify(user), 
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    localStorage.removeItem("authUser");
    localStorage.setItem("authUser", JSON.stringify(data));


  } catch (error) {
    console.log("Error during login:", error);
  }
}

const fetchAndStoreData = async () => {
  const adminPanel = isMobile
    ? `${process.env.REACT_APP_ADMIN}`
    : `${process.env.REACT_APP_ADMIN_LAPTOP}`

  try {
    const response = await fetch(`${adminPanel}/getJD`)
    const data = await response.json()
    localStorage.removeItem("dataJ")
      localStorage.setItem("dataJ", JSON.stringify(data))
  } catch (error) {
    console.error("Failed to fetch data:", error)
  }
  hideLoader()
  renderApp()
}


showLoader()
fetchAndStoreData()


serviceWorker.unregister()
// serviceWorker.register()
