import React, { useState, useEffect } from "react"
import {
  UncontrolledTooltip,
  Container,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Modal,
  Form,
} from "reactstrap"
import ReactDrawer from "react-drawer"
import "react-drawer/lib/react-drawer.css"
import Tour from "reactour"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation"

import { connect } from "react-redux"
import {
  openModal,
  openRC,
  openMS,
  openTour,
  openWelcome,
  openAnn,
  openAnn2,
  openNot,
} from "store/actions"
import io from "socket.io-client"
import { isMobile } from "react-device-detect"
import "./custom.css"
import lobimg from "../assets/videoframe/Lobby1-min.jpg"
import CommnetCard from "./UIcomponents/commentCard"
import SweetAlert from "react-bootstrap-sweetalert"
import Countdown from "react-countdown"
import ConnectCard from "pages/UIcomponents/connectCard"
import AgendaModal from "./UIcomponents/AgendaModal"
import CustomChat from "./Chat/chat"
import FloatingEmoji from "./FloatingEmoji/emoji"
import { useHistory } from "react-router"

// import 'bootstrap/dist/css/bootstrap.min.css';

// import Alldata from "../assets/localdata.json"

var agendalink = ""
var tawklink = ""

const vidi = {
  marginTop: "55px",
  position: "relative",
  display: "block",
}
const loader = document.querySelector(".loader")
const Page = props => {
  const Alldata = JSON.parse(localStorage.getItem("dataJ"))

  const callApi = (type, videoName) => {
    postData(process.env.REACT_APP_ADMIN + "/like/" + type, {
      videoName,
      Email: obj.Email,
    }).then(data => {
      // console.log(data); // JSON data parsed by `data.json()` call
      setObj(data)
      localStorage.setItem("authUser", JSON.stringify(data))
    })
  }
  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  const DocCallApi = (type, DocName, DocUrl) => {
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }

    postData(process.env.REACT_APP_ADMIN + "/doc/" + type, {
      DocName,
      Email: obj.Email,
      DocUrl,
    }).then(data => {
      // console.log(data); // JSON data parsed by `data.json()` call
      setObj(data)
      localStorage.setItem("authUser", JSON.stringify(data))
    })
  }

  // redirection time
  const [redirection, setredirect] = useState(
    Alldata.data.lobby.edata.Redirection
  )
  const [redirectionTime, setredirectionTime] = useState(
    Alldata.data.lobby.edata.RedirectionTime
  )
  const [redirectionPlace, setredirectPlace] = useState(
    Alldata.data.lobby.edata.RedirectionPlace
  )
  const targetDateforAuditorium = new Date(redirectionTime)

  const isLoggedIn = localStorage.getItem("authUser") ? true : false
  const currentPath = window.location.pathname

  useEffect(() => {
    if (!isLoggedIn) return

    const now = new Date()
    const timeUntilTarget = targetDateforAuditorium - now

    const redirectToAuditorium = () => {
      if (redirection) {
        if (currentPath !== redirectionPlace) {
          window.location.href = redirectionPlace
        }
      }
    }

    if (timeUntilTarget <= 0) {
      redirectToAuditorium()
    } else if (timeUntilTarget > 0) {
      return
    } else {
      const timerId = setTimeout(() => {
        redirectToAuditorium()
      }, timeUntilTarget)

      return () => clearTimeout(timerId)
    }
  }, [isLoggedIn, currentPath, redirectionPlace, targetDateforAuditorium])

  const { modal, onOpenModal } = props
  const { rc, onOpenRC } = props
  const { ms, onOpenMS } = props
  const { tour, onOpenTour } = props
  const { welcome, onOpenWelcome } = props
  const { ann, onOpenAnn } = props
  const { ann2, onOpenAnn2 } = props
  const [curRC, setCurRC] = useState(0)
  const changeRC = theid => {
    console.log(theid)
    setCurRC(theid)
  }
  const [isPgload, setisPgload] = useState(false)
  let btnleft = "0%"
  let btntop = "0%"
  let emojileft = ["0%", "0%", "0%", "0%"]
  let emojitop = ["0%", "0%", "0%", "0%"]
  const { noti, onOpenNot } = props
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const history = useHistory()
  let flagOpen=true;

  const [mssg, setMssg] = useState("")
  const [displayComment, setdisplayComment] = useState()
  // var commentPage = 0;
  let [commentPage, setcommentPage] = useState(0)
  const [commentArray, setcommentArray] = useState([])
  const [Ann, setAnn] = useState([])
  const [name, setname] = useState("")
  const [obj, setObj] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [modal_center, setmodal_center] = useState(false)
  const [soundOn, setsoundOn] = useState(false)
  const [ChatPopupList, setChatPopupList] = useState([])
  const [agendaModalOpen, setAgendaModalOpen] = useState(false)
  const [modalCarnival, onOpenCarnivalModal] = useState(true)

  const socket = io.connect(process.env.REACT_APP_ADMIN_Notification)

  const fetchNotifications = () => {
    fetch(process.env.REACT_APP_ADMIN + "/notification/getAll")
      .then(response => response.json())
      .then(data => {
        const userGroup = obj?.Group;
        const filteredAnnouncements = data.filter((announcement) => {
          return (
            !announcement.Group || 
            announcement.Group === userGroup 
          );
        });
        setAnn(filteredAnnouncements);
      })
      .catch(error => {
        console.log("Error fetching notifications:", error);
      });
  };
  useEffect(() => {
    // window.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(() => {
      if (props.edata.chatroom) {
        CometChatWidget.init({
          appID: "25769989b4520bc6",
          appRegion: "in",
          authKey: "eb9ec815f482349e775f12fa5b379ca4413a6f82",
        }).then(
          response => {
            /**
             * Create user once initialization is successful
             */
            // const obj1 = JSON.parse(localStorage.getItem("authUser"))
            const UID = obj._id
            // const USERNAME = obj.Email
            const USERNAME = obj.Firstname ? obj.Firstname : obj.Email

            // obj.Firstname + " " + (obj.Lastname ? obj.Lastname : " ")
            CometChatWidget.createOrUpdateUser({
              uid: UID,
              name: USERNAME,
            }).then(user => {
              console.log("Initialization completed successfully")
              //You can now call login function.
              CometChatWidget.login({
                uid: UID,
              }).then(
                response => {
                  CometChatWidget.launch({
                    //widgetID: "09132a41-5f98-4f95-8d36-4424d7a3155f",
                    widgetID: "40db02c6-d79f-4bf9-a987-9c815c6c41c7",
                    docked: "true",
                    alignment: "right", //left or right
                    roundedCorners: "true",
                    height: "calc(100% - 250px)",
                    width: "400px",
                    defaultID: "", //default UID (user) or GUID (group) to show,
                    defaultType: "user", //user or group
                  })
                  if (props.edata.chatroomOpen) {
                    setTimeout(() => {
                      CometChatWidget.openOrCloseChat(true)
                    }, 1000)
                  }
                },
                error => {
                  console.log("User login failed with error:", error)
                  //Check the reason for error and take appropriate action.
                }
              )
            })
          },
          error => {
            console.log("Initialization failed with error:", error)
            //Check the reason for error and take appropriate action.
          }
        )
      } else {
        if (document.getElementById("cometchat__widget")) {
          document.getElementById("cometchat__widget").style.display = "none"
        }
      }
    }, 1000)
    // })

    setname(obj.Firstname + "!")
    setTimeout(() => {
      loader.classList.add("loader--hide")
      setisPgload(true)
    }, 2000)
    // $("#root").ready(() => {
    //   loader.classList.add("loader--hide")
    //   setisPgload(true)
    // })

    socket.on("notificationChannel", message => {
      fetchNotifications();

      const { title, des, url, img, refresh, group } = message
      // console.log(message);

      if (message?.refresh === "refresh" || message?.refresh === "Refresh") {
        window.location.reload()
        return
      }

      if (message?.group) {
        if (message?.group == obj.Group) {
          setMssg({
            title,
            des,
            url,
            img,
          })
          onOpenNot()
        }
        return
      }

      setMssg({
        title,
        des,
        url,
        img,
      })
      onOpenNot()
    })
    fetchNotifications();
    return () => socket.disconnect()
  }, [])



  useEffect(async () => {
    if (props.edata.activityValid) {
      let activityArr = localStorage.getItem("activityArr")
      if (!activityArr) {
        activityArr = []
      } else {
        activityArr = JSON.parse(activityArr)
      }
      let currentPageArr = activityArr.filter(
        elem => elem == props.edata.activityData.activityName
      )

      if (!currentPageArr.length) {
        postData(process.env.REACT_APP_ADMIN + "/submitscore", {
          gname: props.edata.activityData.activityName,
          id: obj.Email + "|" + obj.Firstname + "|" + obj.Teamname,
          score: props.edata.activityData.activityPoints,
          typeActivity: props.edata.activityData.activityName,
        }).then(data => {
          // console.log(data); // JSON data parsed by `data.json()` call
          // if(data[0]=="success"){}//for successfully adding points
          activityArr.push(props.edata.activityData.activityName)
          localStorage.setItem("activityArr", JSON.stringify(activityArr))
        })
      }
    }
  }, [])

  // useEffect(async () => {
  //   if (props.edata.TreasureValid) {

  //     let TreasureArr = localStorage.getItem("TreasureArr")
  //     console.log("TreasureArr",props.edata.treasureData)
  //     if (!TreasureArr) {
  //       TreasureArr = []
  //     } else {
  //       TreasureArr = JSON.parse(TreasureArr)
  //     }
  //     let currentPageArr = TreasureArr.filter(
  //       elem => elem == props.edata.activityData.activityName
  //     )
  //     console.log("currentPageArr",currentPageArr)

  //     // if (!currentPageArr.length) {
  //     //   postData(process.env.REACT_APP_ADMIN + "/submitscore", {
  //     //     gname: props.edata.activityData.activityName,
  //     //     id: obj.Email + "|" + obj.Firstname + "|" + obj.Teamname,
  //     //     score: props.edata.activityData.activityPoints,
  //     //     typeActivity: props.edata.activityData.activityName,
  //     //   }).then(data => {
  //     //     // console.log(data); // JSON data parsed by `data.json()` call
  //     //     // if(data[0]=="success"){}//for successfully adding points
  //     //     TreasureArr.push(props.edata.activityData.activityName)
  //     //     localStorage.setItem("TreasureArr", JSON.stringify(TreasureArr))
  //     //   })
  //     // }
  //   }
  // }, [])
  
  const tresureClick = (name) => {
    if (props.edata.TreasureValid) {
      // Check if the name matches any item in treasureData admin panel
    let gname = props.edata.treasureData.find((item) => {
      if (item.activityName === name) {
        return item;
      }
    });
    if (gname) {
      console.log("match", gname);
      let matchedItemName = gname.activityName;
      // Retrieve TreasureArr from localStorage or initialize it
      let TreasureArr = localStorage.getItem("TreasureArr");
      if (!TreasureArr) {
        TreasureArr = [];
      } else {
        TreasureArr = JSON.parse(TreasureArr);
      }
      // Check if matchedItemname exists in TreasureArr
     let currentPageArr = TreasureArr.find((item) => item === matchedItemName);
      console.log("cur page",currentPageArr)
  
     if(!currentPageArr) {
        postData(process.env.REACT_APP_ADMIN + "/submitscore", {
          gname: gname.activityName,
          id: obj.Email + "|" + obj.Firstname + "|" + obj.Teamname,
          score: gname.activityPoints,
          typeActivity: gname.activityName,
        }).then(data => {
          // console.log(data); // JSON data parsed by `data.json()` call
          // if(data[0]=="success"){}//for successfully adding points
          TreasureArr.push(matchedItemName)
          alert("Congratulations! You found an item. You have earned " + gname.activityPoints + " points")
          localStorage.setItem("TreasureArr", JSON.stringify(TreasureArr))
        })
      }
    
    } else {
      console.log("No match found.");
      return false;
    }
    }
  };
  

  async function handleNotification() {
    Notification.requestPermission()
      .then(result => {
        if (result === "granted") {
          console.log("Notification permission granted.")
          setNoti("Notification permission granted")
        } else {
          setNoti("permission denied")
          console.log("Permission denied for notifications.")
        }
      })
      .catch(error => {
        setNoti("An error occurred while requesting notification permission:")
        console.error(
          "An error occurred while requesting notification permission:",
          error
        )
      })
  }

  // useEffect(() => {
  //   handleNotification();
  // }, [])

  const toggleAgendaModal = () => {
    setAgendaModalOpen(!agendaModalOpen)
  }
  const disableBody = () => disableBodyScroll(document.body)
  const enableBody = () => enableBodyScroll(document.body)

  const onDrawerClose = () => {
    setOpen(false)
  }
  const onCatalogueClose = () => {
    setOpen(false)
  }
  const [position, setPosition] = useState()
  const [positionCatalogue, setPositionCatalogue] = useState()
  const [open, setOpen] = useState(false)
  const [openCatalogue, setOpenCatalogue] = useState(false)
  const toggleRightDrawer = () => {
    setPosition("right")
    setOpen(!open)
  }
  const closeTour = () => {
    onOpenTour()
  }

  const handleTour = () => {
    setTimeout(() => {
      onOpenWelcome()
    }, 500)
    onOpenTour()
  }
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_like, setmodal_like] = useState(false)
  const toggleLike = () => {
    setmodal_like(!modal_like)
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }


  const onCDrawerClose = () => {
    setcommentPage(0)
    setCOpen(false)
  }
  const [cposition, setCPosition] = useState()
  const [copen, setCOpen] = useState(false)
  const toggleCRightDrawer = () => {
    // commentPage = 0;
    setcommentPage(0)
    fetchcomments("clear")
    // setdisplayComment(

    // )
    setCPosition("right")
    setCOpen(!copen)
  }

  const remClass = () => {
    Alldata.data.videolibrary.data.areas[curRC].data.forEach((doc, ind) => {
      document.getElementById(doc.name).classList.remove("mm-active")
    })
  }

  function socketEmojiTrigger(target) {
    const emojiData = {
      target: target,
      id: obj?._id,
      Firstname: obj?.Firstname,
    }
    timesRun = 300
    emojii(
      emojiData?.target,
      emojiData?.id !== obj?._id ? emojiData?.Firstname : "You"
    )
    socket.emit("emojireact", emojiData)
  }
  var timesRun = 300
  function emojii(target, clientName) {
    console.log(target)

    // Shim layer with setTimeout fallback
    window.requestAnimationFrame = (function () {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60)
        }
      )
    })()

    timesRun = 300

    var $parenti = $(target)
    var $elements = $parenti.find("img")
    var $nameElement = $parenti.find(".nameElement")

    var vertSpeed = 2
    var horiSpeed = 2

    // Set up height and width for animation boundaries
    var height = $parenti.height()
    var width = $parenti.width()

    $parenti
      .css("position", "relative")
      .css("overflow", "hidden")
      .fadeIn("fast")

    // Set the client name text and style
    $nameElement.text(clientName).css({
      position: "absolute",
      color: "white",
      fontSize: "14px",
      textAlign: "center",
      width: "fit-content",
      display: "block",
      top: "50px",
      left: "0%", // Center the name above the emoji
    })

    // Store all the data for animation
    var itemls = []
    for (var i = 0; i < $elements.length; i++) {
      var $element = $($elements[i]),
        elementWidth = $element.width(),
        elementHeight = $element.height()

      $element.css({
        position: "absolute",
        visibility: "visible",
        display: "block",
        opacity: 1,
      })

      var iteml = {
        element: $element[0],
        elementHeight: elementHeight,
        elementWidth: elementWidth,
        ySpeed: -vertSpeed,

        omega: (2 * Math.PI * horiSpeed) / (width * 60), // omega= 2Pi*frequency
        random: (Math.random() / 2 + 0.5) * i * 10000, // random time offset
        x: function (time) {
          return (
            ((Math.sin(this.omega * (time + this.random)) + 1) / 2) *
            (width - elementWidth)
          )
        },
        y: height + (Math.random() + 1) * i * elementHeight,
      }
      itemls.push(iteml)
    }

    var counter = 0

    // Animation step function
    var animationStep = function () {
      var time = +new Date() // Unix time in ms
      var check = counter % 10 === 0

      for (var i = 0; i < itemls.length; i++) {
        var iteml = itemls[i]

        let transformString =
          "translate3d(" + iteml.x(time) + "px," + iteml.y + "px,0px)"
        iteml.element.style.transform = transformString
        iteml.element.style.webkitTransform = transformString

        iteml.y += iteml.ySpeed
        if (check && iteml.y < -iteml.elementHeight) {
          iteml.y = height // Reset to the bottom if out of bounds
        }
      }

      if (itemls.length > 0) {
        var firstItem = itemls[0]
        $nameElement.css({
          transform: `translate3d(${firstItem.x(time)}px, ${
            firstItem.y
          }px, 0px)`,
          webkitTransform: `translate3d(${firstItem.x(time)}px, ${
            firstItem.y
          }px, 0px)`,
        })
      }

      counter = (counter + 1) % 10

      if (timesRun > 0) {
        requestAnimationFrame(animationStep)
        timesRun--
      } else {
        $parenti.fadeOut("slow", function () {
          $elements.remove()
          $nameElement.remove()
        })
      }
    }

    requestAnimationFrame(animationStep)
  }
  console.log("props",props)

  async function fetchcomments(event) {
    let response1 = await fetch(
      process.env.REACT_APP_ADMIN + "/comment/getcomments",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ commentPage: commentPage }), // body data type must match "Content-Type" header
      }
    )
      .then(response1 => response1.json())
      .then(data => {
        if (event == "clear") {
          setcommentArray(data)
        } else if (event == "new") {
          setcommentArray(data)
        } else {
          // console.log("jk: ",data)
          // if(data.length)
          setcommentArray(commentArray => [...commentArray, ...data])
        }
      })
    // const res=response1.json()
    // setcommentArray(res);
    // console.log(response1);
    // fetch(process.env.REACT_APP_ADMIN+'/comment/getcomments', { commentPage:commentPage })
    // .then(response => response.json())
    // .then(data => {
    //  setcommentArray(data)
    // console.log(data);
    //  })
  }

  async function handleSubmit(event) {
    // console.log("here we go");
    event.preventDefault()
    if (document.getElementById("floatingnameInput").value != "") {
      const response = await fetch(process.env.REACT_APP_ADMIN + "/comment", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          Comment: document.getElementById("floatingnameInput").value,
          Email: obj.Email,
          Firstname: obj.Firstname,
        }), // body data type must match "Content-Type" header
      })
      // console.log(response.status);

      document.getElementById("floatingnameInput").value = ""
      fetchcomments("new")
    } else {
      console.log("Comment not submitted")
    }
  }
  useEffect(() => {
    socket.on("emojireactresponse", emojiData => {
      if (emojiData?.id !== obj?._id) {
        console.log("Emoji reaction response received:", emojiData)
        timesRun = 300
        emojii(
          emojiData?.target,
          emojiData?.id !== obj?._id ? emojiData?.Firstname : "You"
        )
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  const toggleOpenPdf = async pdf => {
    try {
      await loadPDF(pdf)
      onOpenAnn2()
    } catch (error) {
      console.error("Error loading the PDF:", error)
    }
  }

  const [childname,  setchildname] = useState("")
  const [childage,  setage] = useState("")
  const handleChildData = () => {
    if(!childname && !childage){
      alert("Fill details to continue")
    }else{
      postData(process.env.REACT_APP_ADMIN + "/updateProfile/updateChildData" , {
        Email: obj.Email,
        childage,
        childname
      }).then(data => {
        console.log(data); // JSON data parsed by `data.json()` call
        setObj(data)
      localStorage.setItem("authUser", JSON.stringify(data))
        onOpenCarnivalModal(false)
      })
    }
    
  }

  const redirectRestrict = () => {}

  if (
    props.edata.restriction &&
    !props.edata.restrictiondata.includes(obj.Email)
  ) {
    return (
      <Modal size="xl" isOpen={true} centered={true} style={{ width: "auto" }}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">{props.edata.rData.title} </h5>
          <a href="/page/lobby">
            <button type="button" className="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </a>
        </div>
        <div className="modal-body">
          <h6>{props.edata.rData.body}</h6>
          <br />
          {/* { console.log(Date.parse(props.edata.rData.timer))} */}
          <center style={{ paddingBottom: "40px", fontSize: "xx-large" }}>
            <Countdown
              date={Date.parse(props.edata.rData.timer)}
              intervalDelay={0}
              precision={3}
              renderer={props => (
                <div>
                  {props.days} Days : {props.hours} Hours : {props.seconds}{" "}
                  Seconds
                </div>
              )}
            />
          </center>
          <center>
            <a href="/page/lobby">
              <button className="btn btn-primary">Go Back</button>
            </a>
          </center>
        </div>
      </Modal>
    )
  } else {
    return (
      <React.Fragment>
        <Container style={{ padding: 0, position: "relative" }} fluid>
          <Modal
            size="lg"
            isOpen={modal_center}
            toggle={() => {
              setmodal_center(!modal_center)
            }}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Choose a person to chat</h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_center(!modal_center)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {ChatPopupList.map((item, index) => (
                <ConnectCard
                  key={index}
                  connectPicture={item.picture}
                  connectName={item.name}
                  connectDesignation={item.designation}
                  connectChatText={item.chatdata.btntext}
                  connectChatUid={item.chatdata.uidChat}
                  connectBCardText={item.bcarddata.btntext}
                  connectBCardEmail={item.bcarddata.email}
                />
              ))}
            </div>
          </Modal>

        

          <Modal size="lg" isOpen={ann} toggle={onOpenAnn} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                Announcements &nbsp; <i className="bx bxs-bell-ring" />
              </h5>
              <button
                onClick={onOpenAnn}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              // style={{
              //   backgroundColor: "#1E1E1E",
              //   border: "1px solid #1E1E1E",
              // }}
            >
              <center>
                {Ann.map((a, idx) => (
                  <Card
                    key={idx}
                    className="py-2 px-5"
                    style={{
                      backgroundColor: "rgb(0,0,0,0.42",
                      color: "rgb(255,255,254)",
                    }}
                  >
                    {a.img && (
                      <CardImg
                        top
                        className="img-fluid"
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                          encodeURIComponent(a.img) +
                          "?alt=media"
                        }
                        alt="Skote"
                      />
                    )}

                    <CardBody>
                      <center>
                        <CardTitle className="mt-0">{a.title}</CardTitle>
                        <CardText>{a.des}</CardText>
                        {a.url && (
                          <a target="_blank" href={encodeURI(a.url)}>
                            <button
                              className="btn btn-danger"
                              style={{
                                margin: "1rem",
                                color: "white",
                                backgroundColor: "#CCB9AA",
                                border: "1px solid #CCB9AA",
                              }}
                            >
                              Visit
                            </button>
                          </a>
                        )}
                      </center>
                    </CardBody>
                  </Card>
                ))}
              </center>
            </div>
          </Modal>

          <Modal
            size="xl"
            isOpen={ms}
            toggle={onOpenMS}
            centered={true}
            style={{ width: "auto" }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Photo Mosaic</h5>
              <button
                type="button"
                onClick={onOpenMS}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <iframe
                allow="camera; microphone"
                title="test"
                className="embed-responsive-item mosaic"
                src={encodeURI("https://demo-mosaic.herokuapp.com/")}
              />
            </div>
          </Modal>

          <Modal
            size="xl"
            isOpen={rc}
            toggle={onOpenRC}
            centered={true}
            style={{
              // maxWidth: "85vw",
              // maxHeight:"100vh",
              // position:"fixed",
              // left:"15vh",
              // width:"85vw",
              overflowY: "auto",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-2">
                {Alldata.data.videolibrary.data.areas[curRC].name}
              </h5>
              <button
                type="button"
                onClick={onOpenRC}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row" style={{}}>
                <div className="col-sm-8 col-md-8">
                  <iframe
                    id={Alldata.data.videolibrary.data.areas[curRC].name}
                    src={
                      Alldata.data.videolibrary.data.areas[curRC].data[0].url
                    }
                    // {
                    // "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                    // encodeURIComponent(
                    // Alldata.data.videolibrary.data.areas[4].data[0].url
                    // ) +
                    // "?alt=media"
                    // }
                    height="300px"
                    width="100%"
                  ></iframe>
                </div>
                <div
                  className="col-sm-4 col-md-4"
                  style={{ overflowY: "auto", maxHeight: "70vh" }}
                >
                  <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
                    {Alldata.data.videolibrary.data.areas[curRC].data.map(
                      (doc, ind) => (
                        <li
                          key={ind}
                          style={{
                            padding: "5px",
                          }}
                        >
                          <div className="d-flex justify-content-between w-100">
                            <h5
                              id={doc.name}
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                remClass()
                                document
                                  .getElementById(doc.name)
                                  .classList.add("mm-active")
                                document.getElementById(
                                  Alldata.data.videolibrary.data.areas[curRC]
                                    .name
                                ).src = doc.url
                              }}
                            >
                              {doc.name}
                            </h5>
                            {/* { obj.likedVideos && obj.likedVideos.includes(doc.name) ? 
                          (<button type="button" className="btn btn-danger" onClick={()=>{ callApi("disLike",doc.name) }}>
                            <i className="bx bxs-like d-inline-block font-size-16" ></i>
                          </button>) :
                          (
                            <button type="button" className="btn btn-danger" onClick={()=>{ callApi("addLike",doc.name) }}>
                            <i className="bx bx-like d-inline-block font-size-16" ></i>
                          </button>
                          )
                          } */}
                            {/* {
                            console.log(obj.savedDoc.find(item => item.docname === doc.name))
                          } */}
                            {/* {obj.savedDoc &&
                            obj.savedDoc.find(
                              item => item.docname === doc.name
                            ) ? (
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                  DocCallApi("unsaveDoc", doc.name, doc.url)
                                }}
                              >
                                <i className="bx bxs-briefcase font-size-16">
                                  Saved
                                </i>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                  DocCallApi("saveDoc", doc.name, doc.url)
                                }}
                              >
                                <i className="bx bx-save font-size-18"></i>
                              </button>
                            )} */}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            size="xl"
            isOpen={ann2}
            toggle={onOpenAnn2}
            centered={true}
            style={{ width: "auto" }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Agenda</h5>
              <button
                type="button"
                onClick={onOpenAnn2}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                style={{ width: "100%", height: "100%" }}
                id="pdf-container"
              ></div>
            </div>
          </Modal>

          {/* <Modal
            isOpen={rc}
            toggle={onOpenRC}
            centered={true}
            style={{ width: "auto" }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Help Chat</h5>
              <button
                type="button"
                onClick={onOpenRC}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            {Alldata.data.lobby.data.areas.map((item, i) => (
                <>
                  {(() => {
                    switch (item.id) {
                      case "help": {
                        <div style={{ display: "none" }}>
                          {item.url ? tawklink = item.url : tawklink = ""}
                        </div>
                      }
                    }
                  })()}
                </>
              ))}
              <iframe
                height="450px"
                width="100%"
                title="test"
                className="embed-responsive-item"
                src={encodeURI(
                  tawklink
                )}
              />
            </div>
          </Modal> */}

          <Modal isOpen={noti} toggle={onOpenNot}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                Announcement &nbsp; <i className="bx bxs-bell-ring" />
              </h5>
              <button
                onClick={onOpenNot}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{
                backgroundColor: "transparent",
                // border: "1px solid #CCB9AA",
              }}
            >
              <Card
                style={{
                  backgroundColor: "transparent",
                  color: "#CCB9AA",
                }}
              >
                {mssg.img && (
                  <CardImg
                    top
                    className="img-fluid"
                    src={mssg?.img}
                    alt="Skote"
                  />
                )}

                <CardBody>
                  <center>
                    <CardTitle className="mt-0">{mssg.title}</CardTitle>
                    <CardText>{mssg.des}</CardText>
                    {mssg.url && (
                      <a target="_blank" href={encodeURI(mssg.url)}>
                        <button
                          className="btn"
                          style={{
                            margin: "1rem",
                            color: "#FFFFFF",
                            backgroundColor: "#CCB9AA",
                            border: "1px solid #FFFFFF",
                          }}
                        >
                          Visit
                        </button>
                      </a>
                    )}
                  </center>
                </CardBody>
              </Card>
            </div>
          </Modal>

          <Modal isOpen={welcome} toggle={onOpenWelcome} centered={true}>
            <div
              className="modal-body"
              // style={{
              //   backgroundColor: "#CCB9AA",
              //   border: "1px solid #1E1E1E",
              // }}
            >
              <Card
                className="mb-0"
                style={{ backgroundColor: "transparent", color: "#fff" }}
              >
                <CardBody>
                  <center>
                    <CardTitle
                      className="mt-0"
                      style={{ color: "#fff", fontSize: "1.5rem" }}
                    >
                      <b>Hello, {name}</b>
                    </CardTitle>
                    <CardText style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                      Welcome to DXC Utsav
                    </CardText>
                    {/* <CardImg
                      top
                      className="img-fluid"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/nipsea%2FPersonalised%20guided%20access.jpg?alt=media&token"
                      }
                      alt="Skote"
                    /> */}
                    <CardText style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                      {" "}
                      Would you like us to show you around so that you can
                      familiarise yourself with the event platform?{" "}
                    </CardText>
                    <button
                      onClick={handleTour}
                      style={{
                        margin: "1rem",
                        color: "#FFFFFF",
                        backgroundColor: "transparent",
                        border: "1px solid #FFFFFF",
                      }}
                      className="btn"
                    >
                      Take Tour
                    </button>
                    <button
                      onClick={onOpenWelcome}
                      style={{
                        margin: "1rem",
                        color: "#FFFFFF",
                        backgroundColor: "transparent",
                        border: "1px solid #FFFFFF",
                      }}
                      className="btn"
                    >
                      Skip Tour
                    </button>
                  </center>
                </CardBody>
              </Card>
            </div>
          </Modal>

          {props.data.video ? (
            isMobile ? (
              <img
                loading="lazy"
                width="100%"
                height="100%"
                alt="clickable"
                id="backofpage"
                // src={lobimg}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                  encodeURIComponent(props.data.videoframe) +
                  "?alt=media"
                }
                style={{
                  marginTop: "55px",
                  position: "relative",
                  display: "block",
                }}
              />
            ) : (
              <>
                <img
                  loading="lazy"
                  width="100%"
                  height="100%"
                  alt="clickable"
                  id="backofpage"
                  // src={lobimg}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                    encodeURIComponent(props.data.videoframe) +
                    "?alt=media"
                  }
                  style={{
                    // marginTop: "70px",
                    position: "absolute",
                    display: "block",
                  }}
                />
                <div key="unmutet1" style={{ display: "none" }}>
                  <UncontrolledTooltip target="unmutet1">
                    Mute/Unmute
                  </UncontrolledTooltip>
                  <div
                    id="unmutet1"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      justifyContent: "center",
                      alignItems: "center",
                      transform: "skew(0deg, 0deg)",
                      position: "absolute",
                      top: "5%",
                      right: "5%",
                      zIndex: "2",
                    }}
                  >
                    {soundOn ? (
                      <span
                        onClick={() => {
                          var video = document.getElementById("vid-2")
                          video.muted = !video.muted
                          setsoundOn(!soundOn)
                        }}
                        className="mdi mdi-volume-high"
                      />
                    ) : (
                      <span
                        onClick={() => {
                          var video = document.getElementById("vid-2")
                          video.muted = !video.muted
                          setsoundOn(!soundOn)
                        }}
                        className="mdi mdi-volume-off"
                      />
                    )}
                  </div>
                </div>
                <video
                  id="vid-2"
                  preload="auto"
                  width="100%"
                  height="100%"
                  style={vidi}
                  loop
                  autoPlay
                  muted
                  onLoad={() => {
                    this.play()
                  }}
                >
                  <source
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                      encodeURIComponent(props.data.url) +
                      "?alt=media"
                    }
                    autoPlay
                    loop
                    type="video/mp4"
                  />
                </video>
              </>
            )
          ) : (
            <img
              loading="lazy"
              width="100%"
              height="100%"
              alt="clickable"
              id="backofpage"
              src={
                "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                encodeURIComponent(props.data.url) +
                "?alt=media"
              }
              style={{
                marginTop: "55px",
                position: "relative",
                display: "block",
              }}
            />
          )}

          {/* {props.edata.tour && (
          <Tour
            onRequestClose={closeTour}
            steps={props.edata.tourdata}
            isOpen={tour && isPgload}
            // maskClassName="mask"
            className="helper"
            rounded={5}
            accentColor="#00A982"
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
          />
        )} */}

          {props.edata.tour && (
            <Tour
              onRequestClose={closeTour}
              steps={props.edata.tourdata}
              isOpen={tour && isPgload}
              // maskClassName="mask"
              className="helper"
              rounded={5}
              accentColor={"rgb(255,255,254)"}
              onAfterOpen={disableBody}
              onBeforeClose={enableBody}
            />
          )}




          {props.edata.gameCollection && !obj.childname && (
             <Modal
             isOpen={modalCarnival}
             // toggle={() => { onOpenCarnivalModal(!modalCarnival) }}
             centered={true}
             style={{ width: "auto" }}
           >
             <div className="modal-header">
               <h5 className="modal-title mt-0">Carnival</h5>
               {/* <button
                 type="button"
                 onClick={() => { onOpenCarnivalModal(!modalCarnival) }}
                 className="close"
                 data-dismiss="modal"
                 aria-label="Close"
               >
                 <span aria-hidden="true">&times;</span>
               </button> */}
             </div>
  
             <div className="modal-body text-white">
               <AvForm>
                 <AvField
                   name="childname"
                   label="Enter your child's name"
                   value={childname}
                   className="form-control mb-3"
                   placeholder="Fullname "
                   type="text"
                   onChange={e => {
                       setchildname(e.target.value)
                     }}
                   required
                 />
  
                 <AvField
                   name="childage"
                   label="Enter your child's age"
                   value={childage}
                   className="form-control"
                   placeholder="Enter your child child age"
                   type="number"
                   onChange={e => {
                       setage(e.target.value)
                     }}
                   required
                 />
  
                 <div className="text-center mt-4">
                   <button
                     type="submit"
                     style={{ backgroundColor: "grey",color:"white", borderRadius:"5px", border:"none" ,cursor:"pointer" }}
                     onClick={()=>{
                       handleChildData()
                     }}
                   >
                     Submit
                   </button>
                 </div>
                 
               </AvForm>
             </div>
           </Modal>
          )}

          {props.edata.slido && (
            <>
              <div
                style={{
                  display: "none",
                }}
              >
                {isMobile
                  ? ((btnleft = props.edata.slidodata.mbuttonleft),
                    (btntop = props.edata.slidodata.mbuttontop))
                  : ((btnleft = props.edata.slidodata.buttonleft),
                    (btntop = props.edata.slidodata.buttontop))}
              </div>
              <button
                style={{
                  backgroundColor: "#1E1E1E",
                  color: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  width: "5rem",
                  zIndex: 5,
                  position: "absolute",
                  bottom: props.edata.slidodata.bottom,
                  left: btnleft,
                  top: btntop,
                }}
                type="button"
                className="btn btn-danger"
                onClick={toggleRightDrawer}
                disabled={open}
              >
                {props.edata.slidodata.buttontext}
              </button>
              <ReactDrawer
                style={{ zIndex: 9999 }}
                open={open}
                position={position}
                onClose={onDrawerClose}
              >
                <iframe
                  style={{ position: "relative", top: "0%" }}
                  src={props.edata.slidodata.link}
                  height="100%"
                  width="100%"
                />
              </ReactDrawer>
            </>
          )}
          {props.edata.image && (
            <>
              <div
                style={{
                  display: "flex",
                  transform: "skew(0deg, 0deg)",
                  zIndex: 1,
                  position: "absolute",
                  width: "100%",
                  top: "0%",
                }}
              >
                <img
                  loading="lazy"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                    encodeURIComponent(props.edata.imageoverlay.img) +
                    "?alt=media"
                  }
                  style={{ position: "relative", top: "0%" }}
                  height="100%"
                  width="100%"
                />
              </div>
            </>
          )}

          {props.edata.comment && (
            <>
              <div
                style={{
                  display: "none",
                }}
              >
                {isMobile
                  ? ((btnleft = props.edata.commentdata.mbuttonleft),
                    (btntop = props.edata.commentdata.mbuttontop))
                  : ((btnleft = props.edata.commentdata.buttonleft),
                    (btntop = props.edata.commentdata.buttontop))}
              </div>
              <UncontrolledTooltip target="cm">
                {props.edata.commentdata.buttontext}
              </UncontrolledTooltip>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transform: "skew(0deg, 0deg)",
                  zIndex: 5,
                  position: "absolute",
                  left: btnleft,
                  top: btntop,
                }}
                id="cm"
                onClick={toggleCRightDrawer}
                disabled={copen}
              >
                <img
                  loading="lazy"
                  className="ani"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                    encodeURIComponent(props.edata.commentdata.img) +
                    "?alt=media"
                  }
                />
              </div>
              <ReactDrawer
                style={{
                  zIndex: 9999,
                }}
                open={copen}
                position={cposition}
                onClose={onCDrawerClose}
              >
                <div
                  style={{
                    overflow: "auto",
                    maxHeight: "100vh",
                  }}
                >
                  {/* <iframe
                style={{ position: "relative", top: "0%" }}
                src={props.edata.commentdata.link}
                height="100%"
                width="100%"
              /> */}
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h5"
                        style={{
                          // marginTop:"100px",
                          color: "#495057",
                        }}
                      >
                        {props.edata.commentdata.title}
                      </CardTitle>
                      {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}

                      <Form id="cform" onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingnameInput"
                            name="Comment"
                            placeholder="Enter Name"
                          />
                          <label htmlFor="floatingnameInput">
                            Enter Your Comment
                          </label>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                        <div
                          style={{
                            cursor: "pointer",
                            marginLeft: "0.30rem",
                            float: "right",
                          }}
                          className="btn btn-primary "
                          onClick={() => {
                            // commentPage = 0;
                            setcommentPage(0)
                            fetchcomments("clear")
                          }}
                        >
                          <i className="mdi mdi-reload"></i>{" "}
                        </div>
                      </Form>
                      {commentArray.map((b, idx) => (
                        <CommnetCard
                          key={idx}
                          cardTitle={commentArray[idx].Firstname}
                          cardText={commentArray[idx].comment}
                        ></CommnetCard>
                      ))}
                      <div
                        style={{
                          cursor: "pointer",
                          marginLeft: "0.30rem",
                          marginTop: "0.50rem",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setcommentPage((commentPage += 1))
                          fetchcomments()
                        }}
                      >
                        Load More
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </ReactDrawer>
            </>
          )}

          {props.edata.customChatroom && <CustomChat />}

          {props.edata.back && (
            <>
              <div>
                <UncontrolledTooltip target="bk">
                  {props.edata.backbutton.buttontext}
                </UncontrolledTooltip>
                <div
                  style={{
                    display: "none",
                  }}
                >
                  {isMobile
                    ? ((btnleft = props.edata.backbutton.mbuttonleft),
                      (btntop = props.edata.backbutton.mbuttontop))
                    : ((btnleft = props.edata.backbutton.buttonleft),
                      (btntop = props.edata.backbutton.buttontop))}
                </div>
                <a href={props.edata.pbdata.link}>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transform: "skew(0deg, 0deg)",
                      zIndex: 5,
                      position: "absolute",
                      left: btnleft,
                      top: btntop,
                      width: props.edata.backbutton.buttonwidth,
                    }}
                    id="bk"
                  >
                    <img
                      loading="lazy"
                      className="ani"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                        encodeURIComponent(props.edata.backbutton.img) +
                        "?alt=media"
                      }
                    />

                    {/* <button className="btn btn-danger" style={{backgroundColor:"#174D91",borderColor:"#174D91"}}><i className="bx "></i>&nbsp;&nbsp;&nbsp;Logout&nbsp;&nbsp;</button> */}
                  </div>
                </a>
              </div>
            </>
          )}

          {props.edata.logout && (
            <>
              <div>
                <UncontrolledTooltip target="lg">
                  {props.edata.lgdata.buttontext}
                </UncontrolledTooltip>
                <div
                  style={{
                    display: "none",
                  }}
                >
                  {isMobile
                    ? ((btnleft = props.edata.lgdata.mbuttonleft),
                      (btntop = props.edata.lgdata.mbuttontop))
                    : ((btnleft = props.edata.lgdata.buttonleft),
                      (btntop = props.edata.lgdata.buttontop))}
                </div>
                <a href={"/logout"}>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transform: "skew(0deg, 0deg)",
                      zIndex: 5,
                      position: "absolute",
                      left: btnleft,
                      top: btntop,
                      width: props.edata.lgdata.buttonwidth,
                    }}
                    id="lg"
                  >
                    <img
                      loading="lazy"
                      className="ani"
                      style={
                        {
                          //  width:"100%",
                        }
                      }
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                        encodeURIComponent(props.edata.lgdata.img) +
                        "?alt=media"
                      }
                    />

                    {/* <button className="btn btn-danger" style={{backgroundColor:"#174D91",borderColor:"#174D91"}}><i className="bx "></i>&nbsp;&nbsp;&nbsp;Logout&nbsp;&nbsp;</button> */}
                  </div>
                </a>
              </div>
            </>
          )}

          {props.edata.catalouge && (
            <>
              <div>
                <UncontrolledTooltip target="Catalogue">
                  {props.edata.catalougeData.hoverText}
                </UncontrolledTooltip>
                <div
                  style={{
                    display: "none",
                  }}
                >
                  {isMobile
                    ? ((btnleft = props.edata.catalougeData.mbuttonleft),
                      (btntop = props.edata.catalougeData.mbuttontop))
                    : ((btnleft = props.edata.catalougeData.buttonleft),
                      (btntop = props.edata.catalougeData.buttontop))}
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "skew(0deg, 0deg)",
                    zIndex: 5,
                    position: "absolute",
                    left: btnleft,
                    top: btntop,
                    width: props.edata.catalougeData.buttonwidth,
                  }}
                  id="Catalogue"
                >
                  <img
                    loading="lazy"
                    className="ani"
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                      encodeURIComponent(props.edata.catalougeData.img) +
                      "?alt=media"
                    }
                    onClick={() => {
                      setOpenCatalogue(!openCatalogue)
                    }}
                  />
                </div>
                <ReactDrawer
                  open={openCatalogue}
                  position={positionCatalogue}
                  onClose={onCatalogueClose}
                >
                  <ul
                    id="catalogueScrollId"
                    className="drawer-main-menu list-unstyled"
                  >
                    {props.edata.catalougeData.list.map((elem, index) => {
                      return (
                        <li className="drawer-menu">
                          <a className="" href={elem.url}>
                            <i className="bx bx-book-add"></i>
                            <span>{elem.name}</span>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </ReactDrawer>
              </div>
            </>
          )}

          {props.data.areas.map((item, i) =>
            (() => {
              switch (item.linktype) {
                case "videoLib": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target="videolib">
                        {item.name}
                      </UncontrolledTooltip>
                      <div
                        id="videolib"
                        onClick={() => {
                          changeRC(item.id)
                          onOpenRC()
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "skew(0deg, 0deg)",
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                      >
                        {!item.noblinking && (
                          <span className="blinkingdot"></span>
                        )}
                      </div>
                    </div>
                  )
                }
              

                case "link": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a href={item.url}>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }

                case "emoji": {
                  return (
                    <>
                      <div id={item.name} className="parenti">
                        <img
                          loading="lazy"
                          className="ani"
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                            encodeURIComponent(item.url) +
                            "?alt=media"
                          }
                        />
                        <h1 className="nameElement"></h1>
                        {/* <img loading="lazy"
                          className="ani"
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                            encodeURIComponent(item.url) +
                            "?alt=media"
                          }
                        />
                        <img loading="lazy"
                          className="ani"
                          src={
                            "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                            encodeURIComponent(item.url) +
                            "?alt=media"
                          }
                        /> */}
                      </div>
                      <div key={i}>
                        <UncontrolledTooltip target={item.id}>
                          {item.name}
                        </UncontrolledTooltip>
                        <div
                          style={{
                            display: "none",
                          }}
                        >
                          {isMobile
                            ? ((emojileft = item.coords.mleft),
                              (emojitop = item.coords.mtop))
                            : ((emojileft = item.coords.left),
                              (emojitop = item.coords.top))}
                        </div>
                        <a>
                          <div
                            id={item.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              transform: "skew(0deg, 0deg)",
                              position: "absolute",
                              width: item.coords.width,
                              height: item.coords.height,
                              top: emojitop,
                              left: emojileft,
                            }}
                            onClick={() => {
                              if (item.soundUrl) {
                                $("#sound" + item.name)[0].play()
                              }
                              $("#" + item.name).fadeIn("slow")
                              socketEmojiTrigger(`#${item.name}`)
                            }}
                          >
                            <img
                              loading="lazy"
                              className="ani"
                              src={
                                "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                                encodeURIComponent(item.url) +
                                "?alt=media"
                              }
                            />
                            {item.soundUrl && (
                              <audio id={"sound" + item.name}>
                                <source
                                  src={
                                    "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                                    encodeURIComponent(item.soundUrl) +
                                    "?alt=media"
                                  }
                                  type="audio/mpeg"
                                ></source>
                              </audio>
                            )}
                          </div>
                        </a>
                      </div>
                    </>
                  )
                }
                case "photobooth": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a
                        href={
                          process.env.REACT_APP_PhotoBooth +
                          "?email=" +
                          obj.Email +
                          "&name=" +
                          obj.Firstname
                        }
                        target="_blank"
                        className=""
                      >
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          // onClick={onOpenMS}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }
                case "mosaic": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={onOpenMS}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }
                case "notification": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={onOpenAnn}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }

                case "rlink": {
                  const [rs, setRS] = useState(false)
                  const togglers = () => {
                    setRS(!rs)
                  }
                  return (
                    <>
                      <Modal
                        isOpen={rs}
                        toggle={togglers}
                        centered={true}
                        style={{ width: "auto" }}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">Opening Soon!</h5>
                          <button
                            type="button"
                            onClick={togglers}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div
                          className="modal-body"
                          style={{ color: "rgb(255,255,254)" }}
                        >
                          {item.data.body}
                        </div>
                      </Modal>
                      <div key={i}>
                        <UncontrolledTooltip target={item.id}>
                          {item.name}
                        </UncontrolledTooltip>

                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={togglers}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </div>
                    </>
                  )
                }

                case "chatLink": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={() => {
                            CometChatWidget.chatWithUser(item.uidChat)
                            CometChatWidget.openOrCloseChat(true)
                          }}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }

                case "linkLike": {
                  return (
                    <>
                      <Modal isOpen={modal_like} toggle={toggleLike}>
                        <div className="modal-header">
                          <h5
                            className="modal-title mt-0"
                            id="myExtraLargeModalLabel"
                          >
                            Liked!
                          </h5>
                          <button
                            onClick={toggleLike}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body" style={{ padding: 0 }}>
                          <Card className="mb-0">
                            <CardBody>
                              <CardText>
                                Thank you for voting this booth! Only one vote
                                counts. Your vote is counted.
                              </CardText>
                            </CardBody>
                          </Card>
                        </div>
                      </Modal>

                      <div key={i}>
                        <UncontrolledTooltip target={item.id}>
                          {item.name}
                        </UncontrolledTooltip>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={toggleLike}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </div>
                    </>
                  )
                }

                case "catalogue": {
                  return (
                    <>
                      <div key={i}>
                        <UncontrolledTooltip target={item.id}>
                          {item.name}
                        </UncontrolledTooltip>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          // onClick={onOpenModal}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </div>
                    </>
                  )
                }

                case "agendatypelist": {
                  return (
                    <div key={i}>
                      {/* <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip> */}
                      {/* <Button onClick={toggleAgendaModal}>Show Agenda</Button> */}
                      <AgendaModal
                        isOpen={agendaModalOpen}
                        toggle={toggleAgendaModal}
                      />
                      <div
                        id={item.id}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "skew(0deg, 0deg)",
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                        onClick={toggleAgendaModal}
                      >
                        {!item.noblinking && (
                          <span className="blinkingdot"></span>
                        )}
                      </div>
                    </div>
                  )
                }
                case "pdfAgenda": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={() => toggleOpenPdf(item.url)}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }

                case "elink": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a href={item.url} target="__blank">
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }

                case "externalglink": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a
                        target="__blank"
                        href={
                          item.url +
                          obj.Email +
                          "|" +
                          obj.Firstname +
                          "|" +
                          obj.Teamname +
                          "&admin=" +
                          btoa(process.env.REACT_APP_ADMIN + "/submitscore") +
                          "&platform=" +
                          btoa(window.location.origin + "/leaderboard")
                        }
                      >
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }

                case "docs": {
                  const remClass = () => {
                    item.data.forEach((doc, ind) => {
                      document
                        .getElementById(doc.name)
                        .classList.remove("mm-active")
                    })
                  }
                  function removeBodyCss() {
                    document.body.classList.add("no_padding")
                  }
                  const [modal_center, setmodal_center] = useState(false)
                  function tog_center() {
                    setmodal_center(!modal_center)
                    removeBodyCss()
                  }
                  return (
                    <div key={i}>
                      <div>
                        <Modal
                          target={item.id}
                          size={item.size}
                          className="docsModal"
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center()
                          }}
                          centered={true}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">{item.name}</h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_center(false)
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              {/* <div className="col-sm-6 col-md-6"> */}
                              <div className="">
                                <iframe
                                  allowFullScreen
                                  id={item.name}
                                  src={
                                    "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                                    encodeURIComponent(item.data[0].url) +
                                    "?alt=media"
                                  }
                                  height={item.iframeHeight}
                                  width="100%"
                                ></iframe>
                              </div>
                              {/* <div className="col-sm-6 col-md-6">
                                  <ul>
                                    {item.data.map((doc, ind) => (
                                      <li
                                        key={ind}
                                        style={{
                                          padding: "5px",
                                        }}
                                      >
                                        <div className="d-flex justify-content-between w-100">
                                          <h5
                                            id={doc.name}
                                            className=""
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              remClass()
                                              document
                                                .getElementById(doc.name)
                                                .classList.add("mm-active")
                                              document.getElementById(
                                                item.name
                                              ).src =
                                                "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                                                encodeURIComponent(doc.url) +
                                                "?alt=media"
                                            }}
                                          >
                                            {doc.name}
                                          </h5>
                                          <a
                                            href={
                                              "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                                              encodeURIComponent(doc.url) +
                                              "?alt=media"
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            download="TiE50 Winners List 2021"
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                            >
                                              <i className="mdi mdi-download d-block font-size-16"></i>
                                            </button>
                                          </a>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                               */}
                            </div>
                          </div>
                        </Modal>
                      </div>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      {false ? (
                        <div key={i}>
                          <UncontrolledTooltip target={item.id}>
                            {item.name}
                          </UncontrolledTooltip>
                          <a
                            href={
                              "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                              encodeURIComponent(item.data[0].url) +
                              "?alt=media"
                            }
                            target="_blank"
                          >
                            <div
                              id={item.id}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                transform: "skew(0deg, 0deg)",
                                position: "absolute",
                                width: item.coords.width,
                                height: item.coords.height,
                                top: item.coords.top,
                                left: item.coords.left,
                              }}
                            >
                              {!item.noblinking && (
                                <span className="blinkingdot"></span>
                              )}
                            </div>
                          </a>
                        </div>
                      ) : (
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={() => {
                            tog_center()
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-center"
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      )}
                    </div>
                  )
                }

                case "iframe": {
                  function removeBodyCss() {
                    document.body.classList.add("no_padding")
                  }
                  const [modal_center, setmodal_center] = useState(false)
                  function tog_center() {
                    setmodal_center(!modal_center)
                    removeBodyCss()
                  }
                  return (
                    <div key={i}>
                      <div
                        id={item.id}
                        style={{
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                      >
                        <Modal
                          size={item.size}
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center()
                          }}
                          centered={true}
                          style={{ width: "auto" }}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">{item.name}</h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_center(false)
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <iframe
                              height="450px"
                              width="100%"
                              title="test"
                              className="embed-responsive-item"
                              src={encodeURI(item.url)}
                            />
                          </div>
                        </Modal>
                      </div>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <div
                        id={item.id}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "skew(0deg, 0deg)",
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        {!item.noblinking && (
                          <span className="blinkingdot"></span>
                        )}
                      </div>
                    </div>
                  )
                }

                case "iframe2": {
                  function removeBodyCss() {
                    document.body.classList.add("no_padding")
                  }
                  const [modal_center, setmodal_center] = useState(false)
                  function tog_center() {
                    setmodal_center(!modal_center)
                    removeBodyCss()
                  }
                  return (
                    <div key={i}>
                      <div
                        id={item.id}
                        style={{
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                      >
                        <Modal
                          size={item.size}
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center()
                          }}
                          centered={true}
                          style={{ width: "auto" }}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">{item.name}</h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_center(false)
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <iframe
                              height="470px"
                              width="100%"
                              title="test"
                              className="embed-responsive-item"
                              src={item.url}
                            />
                          </div>
                        </Modal>
                      </div>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <div
                        id={item.id}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "skew(0deg, 0deg)",
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                        onClick={() => {
                          tog_center()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        {!item.noblinking && (
                          <span className="blinkingdot"></span>
                        )}
                      </div>
                    </div>
                  )
                }

                case "inside2": {
                  function removeBodyCss1() {
                    document.body.classList.add("no_padding")
                  }
                  const [modal_center1, setmodal_center1] = useState(false)
                  function tog_center1() {
                    setmodal_center1(!modal_center1)
                    removeBodyCss1()
                  }
                  return (
                    <div key={i}>
                      <div
                        id={item.id}
                        style={{
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                      >
                        <Modal
                          size="lg"
                          isOpen={modal_center1}
                          toggle={() => {
                            tog_center1()
                          }}
                          centered={true}
                          style={{ width: "auto" }}
                        >
                          {/* <div className="modal-header">
                            <h5 className="modal-title mt-0">{item.name}</h5>
                          </div> */}
                          <div className="modal-body">
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_center1(false)
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <iframe
                              width="100%"
                              title="test"
                              className="embed-responsive-item inside2"
                              allow="autoplay"
                              allowFullScreen
                              src={encodeURI(item.url)}
                            />
                          </div>
                        </Modal>
                      </div>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <div
                        id={item.id}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "skew(0deg, 0deg)",
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                        onClick={() => {
                          tog_center1()
                        }}
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        {!item.noblinking && (
                          <span className="blinkingdot"></span>
                        )}
                      </div>
                    </div>
                  )
                }

                case "inside": {
                  return (
                    <div key={i}>
                      <div
                        id={item.id}
                        style={{
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                      >
                        <iframe
                          height="100%"
                          width="100%"
                          title="test"
                          className="embed-responsive-item"
                          allowFullScreen
                          allow="autoplay;camera; microphone"
                          sandbox="allow-same-origin allow-scripts"
                          src={encodeURI(item.url)}
                        />
                      </div>
                    </div>
                  )
                }

                case "inside3": {
                  return (
                    <div key={i}>
                      <div
                        id={item.id}
                        style={{
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                      >
                        <iframe
                          height="100%"
                          width="100%"
                          title="test"
                          className="embed-responsive-item"
                          allowFullScreen
                          allow="autoplay;camera; microphone"
                          sandbox="allow-same-origin allow-scripts allow-downloads allow-top-navigation"
                          src={
                            encodeURI(item.url) +
                            "?email=" +
                            obj.Email +
                            "&name=" +
                            obj.Firstname
                          }
                        />
                      </div>
                    </div>
                  )
                }

                case "inside4": {
                  return (
                    <div key={i}>
                      <div
                        id={item.id}
                        style={{
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                      >
                        <iframe
                          height="100%"
                          width="100%"
                          title="test"
                          className="embed-responsive-item"
                          allowFullScreen
                          allow="autoplay;"
                          sandbox="allow-same-origin allow-scripts allow-downloads allow-top-navigation"
                          src={
                            encodeURI(item.url) +
                            "?id=" +
                            obj.Email +
                            "|" +
                            obj.Firstname +
                            "|" +
                            "&admin=" +
                            btoa(process.env.REACT_APP_ADMIN + "/submitscore") +
                            "&platform=" +
                            btoa(window.location.origin + "/leaderboard")
                          }
                        />
                      </div>
                    </div>
                  )
                }

                case "floatingEmojis": {
                  return (
                    <div
                      key={i}
                      className="emoji-container"
                      style={{
                        position: "absolute",
                        backgroundColor: "transparent",
                        width: "25%",
                        height: "90%",
                        top: "0",
                        left: "0",
                        overflow: "hidden",
                      }}
                    >
                      <FloatingEmoji
                        id={item.id}
                        stylex={{
                          coords: isMobile
                            ? { left: item.coords.mleft, top: item.coords.mtop }
                            : { left: item.coords.left, top: item.coords.top },
                        }}
                      />
                    </div>
                  )
                }

                case "playvideo": {
                  // return (
                  //   <>
                  //     <video
                  //       id={"vid" + item.id}
                  //       src={
                  //         "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                  //         encodeURIComponent(item.video) +
                  //         "?alt=media"
                  //       }
                  //       preload="auto"
                  //       autostart="false"
                  //       width="100%"
                  //       height="100%"
                  //       style={{
                  //         marginTop: "70px",
                  //         position: "relative",
                  //         display: "none",
                  //       }}
                  //       onEnded={() => {
                  //         window.location.href = item.url
                  //       }}
                  //     ></video>
                  //     <UncontrolledTooltip target={item.id}>
                  //       {item.name}
                  //     </UncontrolledTooltip>
                  //     <div
                  //       key={i}
                  //       id={item.id}
                  //       style={{
                  //         cursor: "pointer",
                  //         display: "flex",
                  //         justifyContent: "center",
                  //         alignItems: "center",
                  //         transform: "skew(0deg, 0deg)",
                  //         position: "absolute",
                  //         width: item.coords.width,
                  //         height: item.coords.height,
                  //         top: item.coords.top,
                  //         left: item.coords.left,
                  //       }}
                  //       onClick={() => {
                  //         document.getElementById("vid-2")
                  //           ? (document.getElementById("vid-2").style.display =
                  //               "none")
                  //           : null
                  //         document.getElementById("backofpage")
                  //           ? (document.getElementById(
                  //               "backofpage"
                  //             ).style.display = "none")
                  //           : null
                  //         document.getElementById(
                  //           "vid" + item.id
                  //         ).style.display = "block"
                  //         document.getElementById(
                  //           "vid" + item.id
                  //         ).style.zIndex = "3"
                  //         document.getElementById("vid" + item.id).play();
                  //         <div>
                  //         <UncontrolledTooltip target="skip1">Skip</UncontrolledTooltip>
                  //         <div
                  //           id="skip1"
                  //           // onClick={() => { history.push("/page/lobby") }} //addedy
                  //           onClick={() => {
                  //             //change here for redirection after on click on skip
                  //             history.push("/page/auditorium")
                  //           }}
                  //           style={{
                  //             cursor: "pointer",
                  //             display: "flex",
                  //             justifyContent: "center",
                  //             alignItems: "center",
                  //             transform: "skew(0deg, 0deg)",
                  //             position: "absolute",
                  //             width: "fit-content",
                  //             height: "50px",
                  //             fontSize:"1.5rem",
                  //             top: "30%",
                  //             left: "50%",
                  //             zIndex: "999999",
                  //             backgroundColor:"red",
                  //             padding:"1% 2%",
                  //             borderRadius:"20px",
                  //             border: "1px solid white",
                  //             color: "green",
                  //           }}
                  //         >
                  //          <span>SKIP</span>
                  //         </div>
                  //       </div>

                  //       }}
                  //     >
                  //       {item.blinkingimage && (
                  //         <img
                  //           loading="lazy"
                  //           src={
                  //             "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                  //             encodeURIComponent(item.blinkingimage) +
                  //             "?alt=media"
                  //           }
                  //           style={{
                  //             height: "-webkit-fill-available",
                  //           }}
                  //         />
                  //       )}

                  //       {!item.noblinking && (
                  //         <span className="blinkingdot"></span>
                  //       )}
                  //     </div>
                  //   </>
                  // )
                  return (
                    <>
                      <video
                        id={"vid" + item.id}
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                          encodeURIComponent(item.video) +
                          "?alt=media"
                        }
                        preload="auto"
                        autostart="false"
                        width="100%"
                        height="100%"
                        style={{
                          marginTop: "70px",
                          position: "relative",
                          display: "none",
                        }}
                        onEnded={() => {
                          setIsVideoPlaying(false) // Hide skip button when video ends
                          window.location.href = item.url
                        }}
                        onPlay={() => {
                          setIsVideoPlaying(true) // Show skip button when video starts
                        }}
                      ></video>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <div
                        key={i}
                        id={item.id}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "skew(0deg, 0deg)",
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                        }}
                        onClick={() => {
                          document.getElementById("vid-2")
                            ? (document.getElementById("vid-2").style.display =
                                "none")
                            : null
                          document.getElementById("backofpage")
                            ? (document.getElementById(
                                "backofpage"
                              ).style.display = "none")
                            : null
                          const videoElement = document.getElementById(
                            "vid" + item.id
                          )
                          if (videoElement) {
                            videoElement.style.display = "block"
                            videoElement.style.zIndex = "3"
                            videoElement.play()
                          }
                        }}
                      >
                        {item.blinkingimage && (
                          <img
                            loading="lazy"
                            src={
                              "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                              encodeURIComponent(item.blinkingimage) +
                              "?alt=media"
                            }
                            style={{
                              height: "-webkit-fill-available",
                            }}
                          />
                        )}

                        {!item.noblinking && (
                          <span className="blinkingdot"></span>
                        )}
                      </div>

                      {isVideoPlaying && (
                        <div>
                          <UncontrolledTooltip target="skip1">
                            Skip
                          </UncontrolledTooltip>
                          <div
                            id="skip1"
                            onClick={() => {
                              console.log("clicked")
                              history.push("/page/auditorium") // Change here for redirection after clicking on skip
                            }}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              transform: "skew(0deg, 0deg)",
                              position: "absolute",
                              width: "fit-content",
                              // height: "9%",
                              fontSize: "1.5rem",
                              top: "3%",
                              left: "80%",
                              zIndex: "3",
                              padding: "0% 2.5%",
                              borderRadius: "20px",
                              border: "1px solid white",
                              color: "white",
                              display:"none"
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              SKIP{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="40px"
                                viewBox="0 -960 960 960"
                                width="40px"
                                fill="#fff"
                              >
                                <path d="M521.33-480.67 328-674l47.33-47.33L616-480.67 375.33-240 328-287.33l193.33-193.34Z" />
                              </svg>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )
                }

                case "multichatpopup": {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={() => {
                            setChatPopupList(item.list)
                            setmodal_center(!modal_center)
                          }}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }

                case "glink": {
                  const [gmodal, setgmodal] = useState(false)
                  const [bstate, setbstate] = useState(true)
                  const togglegm = () => {
                    setgmodal(!gmodal)
                  }
                  fetch(
                    process.env.REACT_APP_ADMIN + "/playcheck/?id=" + obj.Email
                  )
                    .then(response => response.json())
                    .then(data => {
                      let a = data.scores.filter(el => {
                        return el.gname == item.name
                      })
                      if (a.length) {
                        // setbstate(false)
                      }
                    })
                  return (
                    <>
                      <Modal
                        size="lg"
                        isOpen={gmodal}
                        toggle={togglegm}
                        // centered={true}
                        style={{ width: "auto" }}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">{item.name}</h5>
                          <button
                            type="button"
                            onClick={togglegm}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div
                          className="modal-body"
                          style={{ color: "rgb(255,255,254)" }}
                        >
                          <h6>The Game: </h6>
                          <p>{item.data.game}</p>
                          <h6>How to Play: </h6>
                          <ol>
                            {item.data.rules.map((rule, index) => {
                              return (
                                <li
                                  key={index}
                                  style={{ paddingLeft: "0.5rem" }}
                                >
                                  {rule}
                                </li>
                              )
                            })}
                          </ol>
                        </div>
                        <div
                          className="modal-footer"
                          style={{
                            color: "#00A982",
                            justifyContent: "center",
                            padding: "10% 0 3% 0",
                            borderTop: 0,
                          }}
                        >
                          {item.data.btrial && (
                            <a target="_blank" href={item.url}>
                              <button
                                style={{
                                  backgroundColor: "#4F178A",
                                  border: "none",
                                }}
                                className="btn btn-primary btn-rounded"
                              >
                                Play 
                                Trial
                              </button>
                            </a>
                          )}
                          {item.data.bfinal && bstate && (
                            <a
                              target="_blank"
                              href={
                                item.url +
                                obj?.Email +
                                "|" +
                                obj?.Firstname +
                                "|" +
                                obj?.Teamname +
                                "&admin=" +
                                btoa(
                                  process.env.REACT_APP_ADMIN + "/submitscore"
                                ) +
                                "&platform=" +
                                btoa(window.location.origin + "/leaderboard")
                              }
                            >
                              <button
                                style={{
                                  backgroundColor: "#4F178A",
                                  border: "none",
                                }}
                                className="btn btn-primary btn-rounded"
                              >
                                Play 
                                Final
                              </button>
                            </a>
                          )}
                          {item.data.bfinal && !bstate && (
                            <button
                              className="btn btn-secondary btn-rounded"
                              disabled
                            >
                              Play 
                              Final
                            </button>
                          )}
                        </div>
                      </Modal>
                      <div key={i}>
                        {/* <UncontrolledTooltip target={item.id}>
                          {item.name}
                        </UncontrolledTooltip> */}

                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                          onClick={togglegm}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </div>
                    </>
                  )
                }

                case "TreasureHunt": {
                  // const [gmodal, setgmodal] = useState(false)
                  const [treasureState, setTreasureState] = useState(false)
                  const toggleTreasure = () => {
                    setTreasureState(!treasureState)
                  }
                  // fetch(
                  //   process.env.REACT_APP_ADMIN + "/playcheck/?id=" + obj.Email
                  // )
                  //   .then(response => response.json())
                  //   .then(data => {
                  //     let a = data.scores.filter(el => {
                  //       return el.gname == item.name
                  //     })
                  //     if (a.length) {
                  //       setbstate(false)
                  //     }
                  //   })
                  return (
                   <>
                   {props.edata.TreasureValid && (
                      <div key={i}>
                      <div
                        id={item.id}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "skew(0deg, 0deg)",
                          position: "absolute",
                          width: item.coords.width,
                          height: item.coords.height,
                          top: item.coords.top,
                          left: item.coords.left,
                          opacity: !treasureState ? 1 : 0.7,
                          cursor: !treasureState ? "pointer" : "not-allowed",

                        }}
                        onClick={() => {
                          if (!treasureState) {
                            // setTreasureState(!treasureState)
                            tresureClick(item?.name);
                          }
                        }}
                      >
                         <img      
                    loading="lazy"
                    className="ani"
                    src={item.url}
                  />
                      </div>
                  </div>
                   )}
                     
                   </>
                  )
                }




             

                // case "imagehover": {
                //   return (
                //     <div key={i}>
                //       <div
                //         id={item.id}
                //         style={{
                //           cursor: "pointer",
                //           display: "flex",
                //           justifyContent: "center",
                //           alignItems: "center",
                //           transform: "skew(0deg, 0deg)",
                //           position: "absolute",
                //           width: item.coords.width,
                //           height: item.coords.height,
                //           top: item.coords.top,
                //           left: item.coords.left,
                //         }}
                //         onMouseEnter={() => {
                //           document.getElementById(
                //             "hover" + item.id
                //           ).style.display = "block"
                //         }}
                //       ></div>
                //       <img loading="lazy"
                //         id={"hover" + item.id}
                //         width={item.hover.coords.width}
                //         height={item.hover.coords.height}
                //         alt="clickable"
                //         src={
                //           "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                //           encodeURIComponent(item.hover.url) +
                //           "?alt=media"
                //         }
                //         style={{
                //           marginTop: "70px",
                //           position: "absolute",
                //           top: item.hover.coords.top,
                //           left: item.hover.coords.left,
                //           display: "none",
                //           zIndex: 5,
                //         }}
                //         onMouseLeave={() => {
                //           document.getElementById(
                //             "hover" + item.id
                //           ).style.display = "none"
                //         }}
                //       />
                //     </div>
                //   )
                // }

                default: {
                  return (
                    <div key={i}>
                      <UncontrolledTooltip target={item.id}>
                        {item.name}
                      </UncontrolledTooltip>
                      <a href={item.url}>
                        <div
                          id={item.id}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "skew(0deg, 0deg)",
                            position: "absolute",
                            width: item.coords.width,
                            height: item.coords.height,
                            top: item.coords.top,
                            left: item.coords.left,
                          }}
                        >
                          {!item.noblinking && (
                            <span className="blinkingdot"></span>
                          )}
                        </div>
                      </a>
                    </div>
                  )
                }
              }
            })()
          )}
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ agenda }) => ({
  modal: agenda.modal,
  rc: agenda.rc,
  ms: agenda.ms,
  tour: agenda.tour,
  welcome: agenda.welcome,
  ann: agenda.ann,
  ann2: agenda.ann2,
  noti: agenda.noti,
})

const mapDispatchToProps = dispatch => ({
  onOpenModal: () => dispatch(openModal()),
  onOpenRC: () => dispatch(openRC()),
  onOpenMS: () => dispatch(openMS()),
  onOpenTour: () => dispatch(openTour()),
  onOpenWelcome: () => dispatch(openWelcome()),
  onOpenAnn: () => dispatch(openAnn()),
  onOpenAnn2: () => dispatch(openAnn2()),
  onOpenNot: () => dispatch(openNot()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Page)
