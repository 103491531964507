import PropTypes, { func } from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  FormGroup,
} from "reactstrap"
import { useHistory } from "react-router-dom"

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import user1 from "../../assets/images/user.d1a7045e.png"
import maleAvatar from "../../assets/images/users/male-avatar.png"
import femaleAvatar from "../../assets/images/users/female-avatar.png"
import othersAvatar from "../../assets/images/users/others-avatar.png"
import { ColorRing } from "react-loader-spinner"

// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

import SweetAlert from "react-bootstrap-sweetalert"
import { includes, size } from "lodash"
import QRCode from "qrcode"

const ProfileBuilder = props => {
  const obj = JSON.parse(localStorage.getItem("authUser"))

  const [prof, setprof] = useState(
    obj?.ProfileImg === "" || !obj?.ProfileImg?.includes("firebase")
      ? user1
      : obj?.ProfileImg
  )

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [comp, setcomp] = useState("")
  const [des, setdes] = useState("")
  const [Teamname, setTeamname] = useState("")
  const [phno, setphno] = useState("")
  const [selectedAvatar, setSelectedAvatar] = useState("")
  const [idx, setidx] = useState(1)
  const [check1, setcheck1] = useState()
  const [qrImageUrl, setQrImageUrl] = useState()
  const history = useHistory()
  const [iloader, setILoader] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(
          obj.Firstname
            ? obj.Firstname
            : " " + " " + obj.Lastname
            ? obj.Lastname
            : " "
        )
        setemail(obj.Email)
        setidx(obj._id)
        setcomp(obj.Company)
        setTeamname(obj.Teamname)
        setdes(obj.Designation)
        setphno(obj.PhoneNumber)
        setcheck1(obj.check1)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      // setTimeout(() => {
      //   props.resetProfileFlag()
      // }, 3000)
    }
  }, [props.success])

  const handleProfileImageUpload = async file => {
    setILoader(true)
    const uploadedImageUrl = await uploadActivity(file)
    if (uploadedImageUrl) {
      setprof(uploadedImageUrl)
      setILoader(false)
    }
  }

  function handleValidSubmit(event, values) {
    props.editProfile(values)
    //alert called
    setAlert(true)
    setredirect(true)
  }

  // Example POST method implementation:
  async function postData2(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }
  const user = JSON.parse(localStorage.getItem("authUser"))

  // postData2(process.env.REACT_APP_ADMIN + "/updateProfile/getprof", {
  //   Email: user.Email,
  // }).then(data => {
  //   // console.log(data) // JSON data parsed by `data.json()` call
  //   if (data.Profile) {
  //     setprof(data.Profile)
  //   }
  // })

  function changeprof(event) {
    const user = JSON.parse(localStorage.getItem("authUser"))
    var fileReader = new FileReader()
    if (event.target.files[0].size > 1100000) {
      alert("File is too big!")
    } else {
      fileReader.readAsDataURL(event.target.files[0])
    }

    // Example POST method implementation:
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      return response.json() // parses JSON response into native JavaScript objects
    }
    fileReader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        // console.log(fileReader.result);
        postData(process.env.REACT_APP_ADMIN + "/updateProfile/prof", {
          Email: user.Email,
          Profile: fileReader.result,
        }).then(data => {
          // console.log(data) // JSON data parsed by `data.json()` call
          setprof(data.ProfileImg)
        })
      },
      false
    )
  }
  const [alert, setAlert] = useState(false)
  const alertToggle = () => {
    setAlert(!alert)
  }
  const [redirect, setredirect] = useState(false)
  const redirectToggle = () => {
    if (redirect) history.push("/dashboard");
  }

  const handleAvatarSelect = avatar => {
    setSelectedAvatar(avatar)
    if (avatar === "maleAvatar") setprof(maleAvatar)
    else if (avatar === "femaleAvatar") setprof(femaleAvatar)
    else if (avatar === "othersAvatar") setprof(othersAvatar)
  }
  const [notis, setNoti] = useState(null)

  const handleNotification = () => {
    Notification.requestPermission()
      .then(result => {
        if (result === "granted") {
          console.log("Notification permission granted.")
          setNoti("Notification permission granted")
        } else {
          setNoti("permission denied")
          console.log("Permission denied for notifications.")
        }
      })
      .catch(error => {
        setNoti("An error occurred while requesting notification permission:")
        console.error(
          "An error occurred while requesting notification permission:",
          error
        )
      })
  }

  const loader = document.querySelector(".loader")

  useEffect(async () => {
    loader.classList.add("loader--hide")
    const qrCodeResponse = await QRCode.toDataURL(obj._id)
    setQrImageUrl(qrCodeResponse)
  }, [])
  return (
    <React.Fragment>
      <SweetAlert
        show={alert}
        success
        title="Login Success!"
        onConfirm={redirectToggle}
        // timeout={4000}
        // timeout={4000}
        confirmBtnStyle={{ backgroundColor: "#4F178A",border:"none", color: "white" }}
      >
        Success
        {/* You will be redirected to the login page. Please enter your email ID to log in. */}
      </SweetAlert>

      <div className="page-content-profile" style={{ height: "100vh",background:"#4F178A", overflowX: "hidden" }}>
        <MetaTags>
          <title>Profile</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="User" breadcrumbItem="Profile" /> */}
          <div className="row">
            <div className="col-12 p-3">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                {/* <button
                  type="button"
                  onClick={() => {
                    history.push("/page/Lobby")
                  }}
                  style={{ cursor: "pointer" }}
                  className="btn btn-sm  font-size-30  "
                  id="vertical-menu-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="40px"
                    viewBox="0 -960 960 960"
                    width="40px"
                    fill="#e8eaed"
                  >
                    <path d="M560.67-240 320-480.67l240.67-240.66L608-674 414.67-480.67 608-287.33 560.67-240Z" />
                  </svg>
                </button> */}
                <h1 className="mb-0 font-size-24 text-white judgecol text-center w-100">
                  Create Your Profile
                </h1>
              </div>
              {/* <h2
                className="text-white judgecol text-center w-100"
                style={{ cursor: "pointer" }}
                onClick={() => handleNotification()}
              >
                permission:--{notis}
              </h2> */}
            </div>
          </div>

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              {/*  */}
            </Col>
          </Row>
          {/* <center>
            <h4 className="card-title mb-4">Change Profle</h4>
          </center> */}
          <Row>
            <Col>
              <Card
                style={{
                  maxWidth: "95%",
                  width: "95%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  padding: "1rem",
                  boxShadow: "2px 2px 7px #fff",
                }}
              >
                <CardBody style={{ width: "100%" }}>
                  <Media>
                    {/* rgb(0, 169, 130) 2px 2px 7px */}
                    <div className="align-self-center">
                      <img
                        loading="lazy"
                        src={prof}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                        // onClick={changeprof()}
                      />
                    </div>
                    <Media body className="text-center align-self-center">
                      <div className="text-muted">
                        <h5 className="text-white">{name}</h5>
                        <p className="text-white mb-1">{email}</p>
                        {/* <p className="mb-0">Id no: #{idx}</p> */}
                      </div>
                    </Media>
                    {/* {qrImageUrl ? (
                      <img
                        loading="lazy"
                        className="zoomed-image"
                        src={qrImageUrl}
                        alt="img"
                      />
                    ) : (
                      "NA"
                    )} */}
                  </Media>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <div
                      className="form-group"
                      style={{ paddingTop: "1rem", color: "white" }}
                    >
                      <AvField
                        name="Name"
                        label="Full Name"
                        value={name}
                        className="form-control"
                        placeholder="Full Name *"
                        type="text"
                        required
                      />
                    </div>

                    {/* <div className="form-group" style={{ paddingTop: "1rem" }}>
                      <AvField
                        name="Company"
                        label="Company"
                        value={comp}
                        className="form-control"
                        placeholder="Company *"
                        type="text"
                        // disabled
                        required
                      />
                    </div>
                    <div className="form-group" style={{ paddingTop: "1rem" }}>
                      <AvField
                        name="Designation"
                        label="Designation"
                        value={des}
                        className="form-control"
                        placeholder="Designation *"
                        type="text"
                        // disabled
                        required
                      />
                    </div>  */}

                    {/* <div className="form-group" style={{ paddingTop: "1rem" }}>
                      <AvField
                        className="form-control"
                        label="Business Units"
                        type="select"
                        name="Teamname"
                        placeholder="Business Units *"
                        required
                        errorMessage="Pick One!"
                      >
                        <option selected disabled value="">
                          Select Business Unit
                        </option>
                        <option value="Advisory">Advisory</option>
                        <option value="Audit">Audit</option>
                        <option value="Enabling Functions">
                          Enabling Functions
                        </option>
                        <option value="isac.x">isac.x</option>
                        <option value="I&EM">I&EM</option>
                        <option value="Marketing">Marketing</option>
                        <option value="National KM">National KM</option>
                        <option value="Tax">Tax</option>
                        <option value="US Operations">US Operations</option>
                      </AvField>
                    </div> */}

                    {/* <div className="form-group" style={{ paddingTop: "1rem" }}>
                      <AvField
                        name="PhoneNumber"
                        label="PhoneNumber"
                        value={phno}
                        className="form-control"
                        placeholder="PhoneNumber (Optional)"
                        type="text"
                      // disabled
                      // required
                      />
                    </div>  */}

                    <div
                      className="form-group"
                      style={{ paddingTop: "1rem", color: "white" }}
                    >
                      <AvField
                        name="Email"
                        label="Email"
                        value={email}
                        className="form-control"
                        placeholder="Email"
                        type="email"
                        disabled
                      />
                      <AvField name="idx" value={idx} type="hidden" />
                    </div>
                    <div
                      className="form-group "
                      style={{
                        paddingTop: "1rem",
                        color: "white",
                        display: "none",
                      }}
                    >
                      <AvRadioGroup
                        name="SelectedAvatar"
                        label="Pick Your Avatar!"
                        errorMessage="Pick one Avatar!"
                      >
                        <div className="justify-content-evenly d-flex">
                          <div
                            onClick={() => {
                              handleAvatarSelect("maleAvatar")
                            }}
                          >
                            <img
                              loading="lazy"
                              className="avatar-md rounded-circle img-thumbnail ="
                              src={maleAvatar}
                              alt="male-Avatar"
                            />
                            <AvRadio label="Male" value="maleAvatar" />
                          </div>
                          <div
                            onClick={() => {
                              handleAvatarSelect("femaleAvatar")
                            }}
                          >
                            <img
                              loading="lazy"
                              className="avatar-md rounded-circle img-thumbnail"
                              src={femaleAvatar}
                              alt="female-Avatar"
                            />
                            <AvRadio label="Female" value="femaleAvatar" />
                          </div>
                          <div
                            onClick={() => {
                              handleAvatarSelect("othersAvatar")
                            }}
                          >
                            <img
                              loading="lazy"
                              className="avatar-md rounded-circle img-thumbnail"
                              src={othersAvatar}
                              alt="others-Avatar"
                            />
                            <AvRadio label="Others" value="othersAvatar" />
                          </div>
                        </div>
                      </AvRadioGroup>
                    </div>
                    {/* <div className="form-group" style={{ paddingTop: "1rem" }}>
                      <AvField
                        name="ProfileImg"
                        label="Profile Picture"
                        className="form-control"
                        type="file"
                        onChange={e => handleProfileImageUpload(e.target.files[0])}
                      />
                      <small>Image should be less than 10kb.</small>
                    </div> */}
                    <div className="mb-1 mt-3" style={{ color: "white" }}>
                      <AvField
                        name="ProfileImg"
                        label="Profile Picture (please click on choose file to upload your photo)(Optional)"
                        className="form-control"
                        placeholder="Profile photo *"
                        type="hidden"
                        value={prof}
                        required
                      />
                      <div style={{display:"flex" }}>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/png,image/jpeg,image/jpg"
                        onChange={e =>
                          handleProfileImageUpload(e.target.files[0])
                        }
                        required
                      />
                       {iloader && (
                        <div
                          // style={{
                          //   position: "absolute",
                          //   top: "69%",
                          //   left: "94%",
                          // }}
                        >
                          <ColorRing
                            visible={true}
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={[
                              "#e15b64",
                              "#f47e60",
                              "#f8b26a",
                              "#abbd81",
                              "#849b87",
                            ]}
                          />
                        </div>
                      )}
                      </div>
                     
                    </div>
                    <div className="mt-2">
                                  <p
                                    className="text-start"
                                    style={{ marginBottom: "1%" }}
                                  >
                                    Please acknowledge the following:{" "}
                                    <span className="text-danger">*</span>
                                  </p>
                                  <AvRadioGroup
                                    inline
                                    name="acknowledgedment"
                                    required
                                    // value="true"
                                  >
                                    <AvRadio
                                      // style={{ backgroundColor: "transparent" }}
                                      label='By submitting my Name, Employee ID, DXC Email ID, photographs and videos of myself and my family members as part of DXC Utsav activities, I voluntarily provide my consent to all DXC-affiliated entities in India including my employment entity (“Company”) for storing and processing my information for the purpose of participation in the cultural event (Utsav) and internal marketing purposes only. I understand that the Company will not share such information on any external platforms without my express consent.'
                                      value="true"
                                    />
                                  </AvRadioGroup>
                                </div>

                    <div className="text-center m-4 ">
                      <Button
                        disabled={iloader ? true : false}
                        type="submit"
                        style={{ background: "#4F178A", border: "#ffffff" }}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProfileBuilder.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(ProfileBuilder)
)
