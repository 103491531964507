import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"

import "./datatables.scss"

import "./lb.css"
import classnames from "classnames"
// import Alldata from "../assets/localdata.json"

const Alldata = JSON.parse(localStorage.getItem("dataJ"))

const Leaderboard = () => {
  const { ExportCSVButton } = CSVExport
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const [ActiveTab, setActiveTab] = useState("Individual")
  const [activeTabContent, setactiveTabContent] = useState("1")
  const loader = document.querySelector(".loader")
  const [IndividualScore, setIndividualScore] = useState([])
  const [TeamScore, setTeamScore] = useState([])

  const toggle1 = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (false) {
        CometChatWidget.init({
          appID: "25769989b4520bc6",
          appRegion: "in",
          authKey: "eb9ec815f482349e775f12fa5b379ca4413a6f82",
        }).then(
          response => {
            /**
             * Create user once initialization is successful
             */
            // const obj1 = JSON.parse(localStorage.getItem("authUser"))
            const UID = obj._id
            const USERNAME =
              obj.Firstname + " " + (obj.Lastname ? obj.Lastname : " ")
            CometChatWidget.createOrUpdateUser({
              uid: UID,
              name: USERNAME,
            }).then(user => {
              console.log("Initialization completed successfully")
              //You can now call login function.
              CometChatWidget.login({
                uid: UID,
              }).then(
                response => {
                  CometChatWidget.launch({
                    //widgetID: "09132a41-5f98-4f95-8d36-4424d7a3155f",
                    widgetID: "40db02c6-d79f-4bf9-a987-9c815c6c41c7",
                    docked: "true",
                    alignment: "right", //left or right
                    roundedCorners: "true",
                    height: "calc(100% - 180px)",
                    width: "400px",
                    defaultID: "", //default UID (user) or GUID (group) to show,
                    defaultType: "user", //user or group
                  })
                  // if (props.edata.chatroomOpen) {
                  if (false) {
                    setTimeout(() => {
                      CometChatWidget.openOrCloseChat(true)
                    }, 1000)
                  }
                },
                error => {
                  console.log("User login failed with error:", error)
                  //Check the reason for error and take appropriate action.
                }
              )
            })
          },
          error => {
            console.log("Initialization failed with error:", error)
            //Check the reason for error and take appropriate action.
          }
        )
      } else {
        if (document.getElementById("cometchat__widget")) {
          document.getElementById("cometchat__widget").style.display = "none"
        }
      }
    }, 1000)
    loader.classList.add("loader--hide")
    let scorr = []
    let Teamscorr = []
    // fetch(process.env.REACT_APP_ADMIN + "/getteamscore")
    //   .then(response1 => response1.json())
    //   .then(async teamdata => {
    //     await teamdata.map((Teamel, Teamindex) => {
    //       let TeamtotalScore = Teamel.scores.reduce(function (
    //         accumulator1,
    //         sc1
    //       ) {
    //         return accumulator1 + sc1.score
    //       },
    //       0)
    //       let Teamitem = {
    //         key: Teamindex,
    //         id: Teamel._id,
    //         name: Teamel.Teamname,
    //         createdAt: Teamel.createdAt,
    //         total: TeamtotalScore,
    //         rank: 0,
    //         // "Knife Ninja": Teamel.scores.filter(Teamel => { return Teamel.gname == "Knife Ninja" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Knife Ninja" })[0].score : 0,
    //         // "Monsters Up": Teamel.scores.filter(Teamel => { return Teamel.gname == "Monsters Up" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Monsters Up" })[0].score : 0,
    //         // "Mr.Gun": Teamel.scores.filter(Teamel => { return Teamel.gname == "Mr.Gun" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Mr.Gun" })[0].score : 0,
    //         // "Flappy Dove": Teamel.scores.filter(Teamel => { return Teamel.gname == "Flappy Dove" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Flappy Dove" })[0].score : 0,
    //         // "TEN": Teamel.scores.filter(Teamel => { return Teamel.gname == "TEN" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "TEN" })[0].score : 0,
    //         // "Quiz": Teamel.scores.filter(Teamel => { return Teamel.gname == "Quiz" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Quiz" })[0].score : 0,
    //         // "Fortune WheTeamel": Teamel.scores.filter(Teamel => { return Teamel.gname == "Fortune WheTeamel" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Fortune WheTeamel" })[0].score : 0,
    //         // "Cricket Challenge": Teamel.scores.filter(Teamel => { return Teamel.gname == "Cricket Challenge" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Cricket Challenge" })[0].score : 0,
    //         // "Easter Memory": Teamel.scores.filter(Teamel => { return Teamel.gname == "Easter Memory" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Easter Memory" })[0].score : 0,
    //         // "Car Rush": Teamel.scores.filter(Teamel => { return Teamel.gname == "Car Rush" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Car Rush" })[0].score : 0,
    //         // "Jigsaw": Teamel.scores.filter(Teamel => { return Teamel.gname == "Jigsaw" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Jigsaw" })[0].score : 0,
    //         // "Pinball": Teamel.scores.filter(Teamel => { return Teamel.gname == "Pinball" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Pinball" })[0].score : 0,
    //         // "Penalty Kicks": Teamel.scores.filter(Teamel => { return Teamel.gname == "Penalty Kicks" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "Penalty Kicks" })[0].score : 0,
    //         // "City Blocks": Teamel.scores.filter(Teamel => { return Teamel.gname == "City Blocks" })[0] ? Teamel.scores.filter(Teamel => { return Teamel.gname == "City Blocks" })[0].score : 0,
    //       }
    //       Alldata.helper.leaderboard.active.map((active, ind) => {
    //         Teamitem[active] = Teamel.scores.filter(Teamel => {
    //           return Teamel.gname == Teamitem.active
    //         })
    //           ? Teamel.scores.filter(Teamel => {
    //               return Teamel.gname == active
    //             })[0]
    //             ? Teamel.scores.filter(Teamel => {
    //                 return Teamel.gname == active
    //               })[0].score
    //             : 0
    //           : 0
    //       })
    //       Teamscorr.push(Teamitem)
    //     })
    //     // sort by value
    //     Teamscorr.sort(function (c, d) {
    //       return d.total - c.total
    //     })
    //     let i = 1
    //     Teamscorr[i - 1].rank = 1
    //     // console.log(Teamscorr[i-1].total);
    //     while (i < Teamscorr.length) {
    //       if (Teamscorr[i - 1].total == Teamscorr[i].total) {
    //         Teamscorr[i].rank = Teamscorr[i - 1].rank
    //       } else {
    //         Teamscorr[i].rank = Teamscorr[i - 1].rank + 1
    //       }
    //       i++
    //     }
    //     const TeamScore = Teamscorr.filter(Teamel => {
    //       return Teamel.name == obj.Teamname
    //     })[0]
    //     console.log("asda : ", TeamScore)
    //     console.log("scorr : ", Teamscorr)
    //     if (TeamScore) {
    //       const TeamObj = {
    //         key: Teamscorr.length,
    //         id: TeamScore._id,
    //         name: TeamScore.name,
    //         createdAt: TeamScore.createdAt,
    //         total: TeamScore.total,
    //         rank: TeamScore.rank,
    //         // "Knife Ninja": TeamScore["Knife Ninja"],
    //         // "Monsters Up": TeamScore["Monsters Up"],
    //         // "Mr.Gun": TeamScore["Mr.Gun"],
    //         // "Flappy Dove": TeamScore["Flappy Dove"],
    //         // "TEN": TeamScore["TEN"],
    //         // "Quiz": TeamScore["Quiz"],
    //         // "Fortune Wheel": TeamScore["Fortune Wheel"],
    //         // "Cricket Challenge": TeamScore["Cricket Challenge"],
    //         // "Easter Memory": TeamScore["Easter Memory"],
    //         // "Car Rush": TeamScore["Car Rush"],
    //         // "Jigsaw": TeamScore["Jigsaw"],
    //         // "Pinball": TeamScore["Pinball"],
    //         // "Penalty Kicks": TeamScore["Penalty Kicks"],
    //         // "City Blocks": TeamScore["City Blocks"],
    //       }
    //       Alldata.helper.leaderboard.active.map((active, ind) => {
    //         TeamObj[active] = TeamScore[active]
    //       })
    //       Teamscorr.splice(0, 0, TeamObj)
    //     } else {
    //       const TeamObj = {
    //         key: Teamscorr.length,
    //         id: obj.id,
    //         name: obj.Teamname,
    //         createdAt: Date.now(),
    //         total: 0,
    //         rank: Teamscorr.length + 1,
    //       }
    //       Teamscorr.splice(0, 0, TeamObj)
    //     }
    //     // console.log(Teamscorr);
    //     setTeamScore(Teamscorr)
    //   })
    fetch(process.env.REACT_APP_ADMIN + "/getscores")
      .then(response => response.json())
      .then(async data => {
        // console.log("data: ", data)
        await data.map((el, index) => {
          let totalScore = el.scores.reduce(function (accumulator, sc) {
            return accumulator + sc.score
          }, 0)

          let totalTime = el.scores.reduce(function (accumulator, sc) {
            return accumulator + sc.time
          }, 0)

          // let totalTime = el.scores.reduce(function (accumulator, sc) {
          //   return accumulator + sc.time;
          // }, 0) / 1000; 
          

          // console.log("tot sc",totalScore)
          // console.log("tot time--",totalTime)

          let item = {
            key: index,
            id: el.id,
            name: el.name,
            createdAt: el.createdAt,
            total: totalScore,
            rank: 0,
            time: totalTime,
          }
          // console.log("tem", item)
          Alldata.helper.leaderboard.active.map((active, ind) => {
            item[active] = el.scores.filter(el => {
              return el.gname == item.active
            })
              ? el.scores.filter(el => {
                  return el.gname == active
                })[0]
                ? el.scores.filter(el => {
                    return el.gname == active
                  })[0].score
                : 0
              : 0
          })
          scorr.push(item)
        })
        // console.log("scorr: ", scorr)
        // sort by value
        scorr.sort(function (a, b) {
          // if (b.total === a.total) {
          //   // If scores are the same, sort by time (ascending)
          //   return a.time - b.time
          // }
          return b.total - a.total
        })

        // based on total score
        let i = 1
        scorr[i - 1] ? (scorr[i - 1].rank = 1) : null
        // console.log(scorr[i-1].total);
        while (i < scorr.length) {
          if (scorr[i - 1].total == scorr[i].total) {
            scorr[i].rank = scorr[i - 1].rank
          } else {
            scorr[i].rank = scorr[i - 1].rank + 1
          }
          i++
        }

        // based on total score and total time
        let currentRank = 1
        scorr[0].rank = currentRank // Assign rank 1 to the first item

        // for (let i = 1; i < scorr.length; i++) {
        //   if (
        //     scorr[i - 1].total === scorr[i].total &&
        //     scorr[i - 1].time === scorr[i].time
        //   ) {
        //     // If both score and time are the same, assign the same rank
        //     scorr[i].rank = scorr[i - 1].rank
        //   } else {
        //     // If score or time is different, increment the rank
        //     currentRank++
        //     scorr[i].rank = currentRank
        //   }
        // }
        const myScore = scorr.filter(el => {
          return el.id == obj.Email
        })[0]

        if (myScore) {
          let msObj = {
            key: scorr.length,
            id: myScore.id,
            name: myScore.name,
            createdAt: myScore.createdAt,
            time: myScore.time,
            total: myScore.total,
            rank: myScore.rank,
            // "Knife Ninja": myScore["Knife Ninja"],
            // "Monsters Up": myScore["Monsters Up"],
            // "Mr.Gun": myScore["Mr.Gun"],
            // "Flappy Dove": myScore["Flappy Dove"],
            // "TEN": myScore["TEN"],
            // "Quiz": myScore["Quiz"],
            // "Fortune Wheel": myScore["Fortune Wheel"],
            // "Cricket Challenge": myScore["Cricket Challenge"],
            // "Easter Memory": myScore["Easter Memory"],
            // "Car Rush": myScore["Car Rush"],
            // "Jigsaw": myScore["Jigsaw"],
            // "Pinball": myScore["Pinball"],
            // "Penalty Kicks": myScore["Penalty Kicks"],
            // "City Blocks": myScore["City Blocks"],
          }
          Alldata.helper.leaderboard.active.map((active, ind) => {
            msObj[active] = myScore[active]
          })
          scorr.splice(0, 0, msObj)
        } else {
          const msObj = {
            key: scorr.length,
            id: obj.id,
            name: obj.Firstname,
            createdAt: Date.now(),
            total: 0,
            time:scorr.time,
            rank: scorr.length + 1,
          }
          scorr.splice(0, 0, msObj)
        }
        // console.log(scorr);
        setIndividualScore(scorr)
      })
  }, [])

  let columns = [
    // {
    //   dataField: 'id',
    //   text: 'Id',
    //   sort: false,
    // },
    // {
    //   dataField: 'key',
    //   text: 'Key',
    //   sort: false,
    // },
    {
      dataField: "rank",
      text: "Rank",
      sort: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
    },
    {
      dataField: "total",
      text: "Total",
      sort: false,
    },
    // {
    //   dataField: "time",
    //   text: "Time(s)",
    //   sort: false,
    // },
     
  ]

  Alldata.helper.leaderboard.active.map((active, ind) => {
    columns.push({
      dataField: active,
      text: active,
      sort: false,
    })
  })

  const defaultSorted = [
    {
      dataField: "total",
      order: "desc",
    },
    
  ]

  const pageOptions = {
    sizePerPage: 200,
    totalSize: IndividualScore.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "12", value: 12 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "200", value: 200 },
    { text: "All", value: IndividualScore.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content" style={{height:"fit-content",minHeight:"100vh",backgroundAttachment:"fixed",backgroundPosition:"center",backgroundImage:"url('https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/apple%20virtual%20platform%2FSelfie_BG.jpg?alt=media&token=72d65319-437f-4d60-ba3e-d238dfedd99e')"}}>
        <div className="container-fluid">
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                style={{cursor: "pointer",fontSize: "1rem"}}
                className={classnames({
                  active: ActiveTab === "Individual",
                })}
                onClick={() => {
                  toggle1("Individual")
                  setactiveTabContent("1")
                }}
              >
                <b>Leaderboard</b>
                
              </NavLink>
              <div id="1"></div>
            </NavItem>
            {/* <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "Team",
                })}
                onClick={() => {
                  toggle1("Team")
                  setactiveTabContent("2")
                }}
              >
                Team Score
              </NavLink>
            </NavItem> */}
          </Nav>
          <TabContent activeTab={activeTabContent} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row style={{display:"grid",placeItems:"center"}}>
                <Col className="col-12">
                  <Card
                    style={{
                      width: "-webkit-fill-available",
                      backgroundColor: "rgba(0, 0, 0, 0.42)",
                      borderRadius: "16px",
                      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                      backdropFilter: "blur(13.9px)",
                      webkitBackdropFilter: "blur(13.9px)",
                    }}
                  >
                    <CardBody>
                      {/* <CardTitle className="h1">
                        Leaderboard
                      </CardTitle> */}
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="key"
                        columns={columns}
                        data={IndividualScore}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columns}
                            data={IndividualScore}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="position-relative" hidden>
                                      <ExportCSVButton
                                        {...toolkitProps.csvProps}
                                      >
                                        Export CSV!!
                                      </ExportCSVButton>
                                    </div>
                                  </Col>
                                </Row>

                                <Row >
                                  <Col xl="12">
                                    <div  className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row  className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                    <div className="text-md-right ms-auto" hidden>    {/*remove hidden atrribute to show pageination tab */}
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h1" style={{ color: "#000" }}>
                        Team Leaderboard
                      </CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="key"
                        columns={columns}
                        data={TeamScore}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columns}
                            data={TeamScore}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Leaderboard
