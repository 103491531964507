import React, { useEffect, useState } from "react"
import Swal from 'sweetalert2'
import "../Attendee/attendee.css"
import { useHistory } from "react-router-dom"
import { isMobile } from "react-device-detect"
import "../PreferencesChat/preferences.css"

import {
  
  Modal,
  Form
} from "reactstrap"
import { object } from "prop-types"

const Attendee = () => {
  const [modalPrefernceVisible, setModalPreferenceVisible] = useState(false)
  const [culturalPrefernceVisible, setCulturalPreferenceVisible] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(1)
  const [userData, setUserData] = useState(""); 
  const [isPgload, setisPgload] = useState(false)
  const [obj, setObj] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [Data, setData] = useState([])
  const loader = document.querySelector(".loader")
  const history = useHistory()
  let objectData;
  const togglePrefernceModal = index => {
    setModalPreferenceVisible(!modalPrefernceVisible)
    setActiveTabIndex(index)
  }

  const toggleCulturalModal = index => {
    setCulturalPreferenceVisible(!culturalPrefernceVisible)
    setActiveTabIndex(index)
  }

  const getModalTitle = () => {
    switch (activeTabIndex) {
      case 1:
        objectData={
          title:"Dietary Preferences",
          label:"Please type your preference here"
        }
        return objectData;
      case 2:
        objectData={
          title:"Allergies",
          label:"Please let us know of any food allergies you might have"
        }
        return objectData;
      case 3:
        objectData={
          title:"Other",
          label: (
            <div dangerouslySetInnerHTML={{ __html: 'Please type ‘Yes’ if you want to opt for the Ramoji City Tour happening on 8th Feb. <br/><br/> The saga of spellbinding cine-magic unfolds, as you step into the truly iconic blockbuster bahubali set.' }} />
          )
            
        
        }
        return objectData;

        case 4:
          objectData={
            title:"Outdoor Activity Options",
            label:"Please type your preference here"
          }
          return objectData;

        
      default:
        return alert("Please select one the Preferences");
    }
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  

    try {
      const response = await fetch(process.env.REACT_APP_ADMIN + "/preferencechatdata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: getModalTitle().title,
          problemText: userData,
          Email:obj.Email
        }),
      });

      const userdata = await response.json();
      console.log(userdata.success);
      if (userdata.success) {
        Swal.fire({
            icon: "success",
          });
      } else {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
      }
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          setUserData("")
    } finally {
      setUserData("")
      setModalPreferenceVisible(false);
      setCulturalPreferenceVisible(false);
    }
  };

  setTimeout(() => {
    loader.classList.add("loader--hide")
    setisPgload(true)
  }, 2000)

  return (
    <React.Fragment>
     <Modal
        isOpen={modalPrefernceVisible}
        toggle={() => togglePrefernceModal()}
        centered={true}
        style={{ width: "auto" }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{getModalTitle().title}</h5>
          <button
            type="button"
            onClick={() => {
                setUserData("")
             setModalPreferenceVisible(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <Form onSubmit={handleFormSubmit}>
      <div className="form-group">
        <label htmlFor="problemInput" style={{color:"#fff",fontSize:"15px"}}>{getModalTitle().label}</label>
        <textarea
          className="form-control"
          id="problemTextarea"
          placeholder="Type  here"
          maxLength={350}
          rows={6}
          value={userData}
          onChange={(e) => setUserData(e.target.value)}
        />
        <br/>
        <br/>
      </div>
      <div className="d-flex justify-content-center">
    <button type="submit" className="submitbtn" >
      Submit
    </button>
  </div>
    </Form>
        </div>
      </Modal>


      <Modal
  isOpen={culturalPrefernceVisible}
  toggle={() => toggleCulturalModal()}
  centered={true}
  style={{ width: "auto" }}
>
  <div className="modal-header">
    <h5 className="modal-title mt-0">{getModalTitle().title}</h5>
    <button
      type="button"
      onClick={() => {
        setUserData("")
        setCulturalPreferenceVisible(false);
      }}
      className="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div className="modal-body">
    <Form onSubmit={handleFormSubmit}>
      <div className="form-group">
        <label htmlFor="activityOptions" style={{color:"#fff",fontSize:"15px"}}>Please choose your morning activity activity for 8th Feb:</label>
        <div>
          <div className="form-check">
            <input 
              type="radio"
              id="yogaOption"
              name="activityOptions"
              value="Yoga"
              className="form-check-input"
              checked={userData === "Yoga"}
              onChange={() => setUserData("Yoga")}
              required
              style={{width: "20px",
              height: "20px",
              border: "1px solid #fff",
              borderRadius: "50%",
              marginRight: "10px",
              backgroundColor: "transparent",
              
            }}
              
            />
            <label htmlFor="yogaOption" style={{color:"#fff",fontSize:"15px"}} className="form-check-label">
              Yoga
            </label>
          </div>
          <br/>
          <div className="form-check">
            <input
              type="radio"
              id="cyclingOption"
              name="activityOptions"
              value="Healthway Cycling Track"
              className="form-check-input"
              checked={userData === "Healthway Cycling Track"}
              onChange={() => setUserData("Healthway Cycling Track")}
              style={{width: "20px",
                height: "20px",
                border: "1px solid #fff",
                borderRadius: "50%",
                marginRight: "10px",
                backgroundColor: "transparent",
                
              }}
              required
             
            />
            <label htmlFor="cyclingOption" style={{color:"#fff",fontSize:"15px"}} className="form-check-label">
              Healthway Cycling Track
            </label>
          </div>
        </div>
      </div>
      <br/>
      <div className="d-flex justify-content-center">
        <button type="submit" className="submitbtn">
          Submit
        </button>
      </div>
    </Form>
  </div>
</Modal>

      <div
        className="main-div"
        style={{
          backgroundColor: "transparent",
          fontFamily: "HelveticaCondensed",
          marginTop: "50px",
        }}
      >
        <div style={{ position: "relative", width: "100vw"}}>
          <img loading="lazy"
            src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/TP%20Immersive%20Experience%2FCustomer%20Preferences.png?alt=media"
            width="100%"
          
          />

          <div
            className="preferences-tab-1"
            onClick={() => togglePrefernceModal(1)}
          ></div>
          <div
            className="preferences-tab-2"
            onClick={() => togglePrefernceModal(2)}
          ></div>
          <div
            className="preferences-tab-3"
            onClick={() => togglePrefernceModal(3)}
          ></div>
           <div
            className="preferences-tab-4"
            onClick={() => toggleCulturalModal(4)}
          ></div>
         
        </div>
      </div>
    </React.Fragment>
  )
}
export default Attendee
