import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, UncontrolledTooltip } from "reactstrap"
import { useHistory } from "react-router"
import { connect } from "react-redux"
import { openWelcome } from "store/actions"
import { isMobile, isMobileOnly } from "react-device-detect"

const vidi = {
  position: "relative",
  height: "100%",
  width: "100%",
  overflow: "hidden scroll",
  display: "none",
}

const Dashboard = props => {
  const { welcome, onOpenWelcome } = props
  const [newImgSrc, setNewImgSrc] = useState("")
  const [fixState,setFixState] = useState(false)

  const videoSrc = isMobile
    ? "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/DXC%202024%2FWalkthrough.mp4?alt=media&token=ddb9c826-2d8b-4255-bff3-cf1121bcbe92"
    : "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/DXC%202024%2FWalkthrough.mp4?alt=media&token=ddb9c826-2d8b-4255-bff3-cf1121bcbe92"
  const imgSrc = isMobile
    ? "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/DXC%202024%2Fclick%20to%20enter.png?alt=media&token=24435841-6ee3-4621-b6d8-05d120b1e7d2"
    : "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/DXC%202024%2Fclick%20to%20enter.png?alt=media&token=24435841-6ee3-4621-b6d8-05d120b1e7d2"

  const history = useHistory()
  function tAdd() {
    const body = document.body
    body.classList.add("vertical-collpsed")
    body.classList.add("sidebar-enable")
  }

  // useEffect(() => {
  //   const checkScreenSize = () => {
  //     if (window.innerWidth < 500) {
  //       window.location.reload();
  //       document.getElementById("skip1").style.display = "none"
  //       setNewImgSrc(
  //         "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/DXC%202024%2Fclick%20to%20enter.png?alt=media&token=24435841-6ee3-4621-b6d8-05d120b1e7d2"
  //       )
  //       setFixState(!fixState);

  //     }else{
  //       document.getElementById("skip1").style.display = "block"

  //         setNewImgSrc(
  //           "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/apple%20virtual%20platform%2FLow%20res%20graphics%2FClick%20to%20Enter.jpg?alt=media&token=840ebfaf-3307-4335-b7e5-6c9394c2e194"
  //         )
  //         setFixState(!fixState);
  //       }
  //     }

  //   checkScreenSize()
  //   window.addEventListener("resize", checkScreenSize)

  //   return () => {
  //     window.removeEventListener("resize", checkScreenSize)
  //   }
  // }, [])

  const loader = document.querySelector(".loader")

  useEffect(() => {
    loader.classList.add("loader--hide")
  }, [])
  return (
    <React.Fragment>
      <div style={{ padding: 0 }} className="page-content">
        <MetaTags>
          <title>DXC Utsav</title>
        </MetaTags>

        <Container style={{ padding: 0 }} fluid>
          <img
            onClick={() => history.push("/page/lobby")}
            id="img-1"
            alt=""
            src={fixState?newImgSrc:imgSrc}
            width="100%"
            loading="lazy"
          />

          <img
            src={imgSrc} // Default fallback image
            srcSet={`
    https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/apple%20virtual%20platform%2FRotate%20Your%20Phone.gif?alt=media&token=797900cb-990e-466a-9182-c675914732ab 500w,
    ${imgSrc} 501w
  `}
            sizes="(max-width: 500px) 100vw, 50vw"
            style={{
              top: "0%",
              width: "100%",
              position: "absolute",
              height: "100%",
              left: "0%",
              objectFit: "cover",
            }}
            loading="eager" 
          />

          <div>
            <UncontrolledTooltip target="skip1">Skip</UncontrolledTooltip>
            <div
              id="skip1"
              // onClick={() => { history.push("/page/lobby") }} //addedy
              onClick={() => {
                //change here for redirection after on click on skip
                history.push("/page/lobby")
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "skew(0deg, 0deg)",
                position: "absolute",
                width: "fit-content",
                // height: "9%",
                fontSize: "1.5rem",
                top: "3%",
                left: "75%",
                zIndex: "3",
                padding: "0 1%",
                borderRadius: "20px",
                border: "1px solid white",
                color: "white",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                SKIP{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="40px"
                  viewBox="0 -960 960 960"
                  width="40px"
                  fill="#fff"
                >
                  <path d="M521.33-480.67 328-674l47.33-47.33L616-480.67 375.33-240 328-287.33l193.33-193.34Z" />
                </svg>
              </span>
            </div>
          </div>
          <div>
            <UncontrolledTooltip target="click2enter">
              Click to Enter
            </UncontrolledTooltip>

            <div
              id="click2enter"
              // onClick={() => { history.push("/page/lobby") }} //addedy
              onClick={() => {
                // onOpenWelcome();
                document.getElementById("vid-1").style.display = "block"
                document.getElementById("img-1").style.display = "none"
                document.getElementById("vid-1").play()
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "skew(0deg, 0deg)",
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0%",
                left: "0%",
              }}
            ></div>
          </div>

          <video
            preload="auto"
            id="vid-1"
            src={videoSrc}
            style={vidi}
            autostart="false"
            onLoadStart={() => {
              tAdd()
            }}
            onEnded={() => {
              history.push("/page/lobby")
            }}
          ></video>
        </Container>
      </div>
    </React.Fragment>
  )
}

// export default Dashboard

const mapStateToProps = ({ agenda }) => ({
  welcome: agenda.welcome,
})

const mapDispatchToProps = dispatch => ({
  onOpenWelcome: () => dispatch(openWelcome()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
