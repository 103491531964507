import React, { useState, useEffect } from "react"
import { QrReader } from "react-qr-reader"
import { MetaTags } from "react-meta-tags"
import Notifications from "pages/UIcomponents/Notification"

const QrScanComponent = () => {
  const [data, setData] = useState("No result")
  const [error, setError] = useState(null)
  const [isFront, setisFront] = useState(false)
  const [obj, setObj] = useState(JSON.parse(localStorage.getItem("authUser")))
  const loader = document.querySelector(".loader")
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  // Hardcoded mapping of QR codes to actions
  const qrCodeActions = {
"f6KsD8p1H3R7o2L9Y5VqB4TcWxMgAiJz":{ type: "API", points:10, gname:"points1"},
"x4VtK7p2J5R9A6C3l8BqW1oMgSvNzYiE":{ type: "API", points:10, gname:"points2"},
"l1VzS5T7K2Y8P3RqN6W9B4oMxIgAeHcJ":{ type: "API", points:10, gname:"points3"},
"g2S5vL9P6H1oW8C3TqB7R4KxAiZmJyNf":{ type: "API", points:10, gname:"points4"},
"n3P6S4K8B5J2lV7R1xW9YiMgAqHcDzEo":{ type: "API", points:10, gname:"points5"},
"u9Z5W8C2P3Q7A6B4H1vKoMxYiSgNrJt":{ type: "API", points:10, gname:"points6"},
"i1J7P5Q3S9R4X8B2W6MlKoVzYgHcAeN":{ type: "API", points:10, gname:"points7"},
"o6N2A8B4W7G1T3Y5R9KpSvIqMxHjLcF":{ type: "API", points:10, gname:"points8"},
"e8B4A1R5X3H7I2W6YgJ9PqVzLmTcNsK":{ type: "API", points:10, gname:"points9"},
"m2T9V6S3W8B5N7J1R4xGqYiHlKoEaPz":{ type: "API", points:10, gname:"points10"},
    // randomString1: { type: "API", points: 1, gname: "test1" },
    // randomString2: { type: "API", points: 2, gname: "test2" },
    // randomString3: { type: "REDIRECT", url: "/redirect1" },
    // randomString4: { type: "REDIRECT", url: "/redirect2" },
"a9Rc2xKwB3VzHnYp8FqT5eUo6XvZiLmG":{type: "REDIRECT", url:"/page/7up"},
"j7OvW4iL3EhQgTmRzY9FpBcXwN8KuVqA":{type: "REDIRECT", url:"/page/Gatorade"},
"x5DmZvY4GtIhKqUoE8RjXlNwP9S2BcW3":{type: "REDIRECT", url:"/page/Mirinda"},
"r7KtQoP8JlZgHwUxN2WcY9A4MvB1S3Dq":{type: "REDIRECT", url:"/page/Mountain_Dew"},
"n4UcWlQxH7PvJyGzA8T3S6B1KoM2RiE9":{type: "REDIRECT", url:"/page/Nimbooz"},
"v2FmJ8TqN1BzG6HwX4YsR9P3D5A7IcKo":{type: "REDIRECT", url:"/page/Pepsi"},
"y1WnO5ZsCvHrJlKuM2RbQ8FtXp3A6EgV":{type: "REDIRECT", url:"/page/Slice"},
"m3S5W6N1P7Z4X9C8G2VbRiQoAqYlHwE9":{type: "REDIRECT", url:"/page/Sting"},
"u8TmKoG7LzQwX3N6RyJiH2V1S4BpA9Ec":{type: "REDIRECT", url:"/page/Tropicana"},
"h2ZqY3XoR1M8U5JlVnA9KgS6BwP4D7Tc":{type: "REDIRECT", url:"/page/Quiz1"},
"k1OoJ4R9P5B6W8E2HxY3I7VgAqTmNsCz":{type: "REDIRECT", url:"/page/Quiz2"},
"t8A2BwS6M3ZqP9RyN5G4KuV7D1EoXlHc":{type: "REDIRECT", url:"/page/Quiz3"},
"p4U1G8X2A3S6N7K5VrMwL9CqBtYiHxJo":{type: "REDIRECT", url:"/page/Quiz4"},
"i5ZrL7B2G4T3H9J8N1XqW6YpCvEoUaKm":{type: "REDIRECT", url:"/page/Quiz5"},
"o2XwR5S8Y9N3K7G6C1VpJ4BtLqAimHuE":{type: "REDIRECT", url:"/page/Quiz6"},
"e7HlA8W6R3P2M5I1BtY9N4CqKgJvSxFo":{type: "REDIRECT", url:"/page/Quiz7"},
"d9X8Z5C2M3V6B7L4Kq1HwP9YiJrEoAsg":{type: "REDIRECT", url:"/page/Quiz8"},
    // Add more mappings as needed
  }



  async function postApiCall(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  // Function to process the QR code
  const processQrCode = async code => {
    const action = qrCodeActions[code]

    if (action) {
      if (action.type === "API") {
        try {
          const response = await postApiCall(
            process.env.REACT_APP_ADMIN + "/submitScore",
            {
              id: obj.Email + "|" + obj.Firstname + "|" + obj.Teamname,
              score: action.points,
              gname: action.gname,
              typeActivity: "Qr"+action.gname,
            }
          )
          if(response && response[0] && response[0]=="Activity score already exists, no update made"){
            setError(`Score Already Updated`)
            setNotification({
              title: "Score Already Updated",
              body: "",
            });
            setShow(true)
          }else{
            setData(`Score Added`)
            setNotification({
              title: "Score Added",
              body: "",
            });
            setShow(true)
          }
        } catch (apiError) {
          setError(`Score failed to update/ Already Updated`)
          setNotification({
            title: "Score failed to update/ Already Updated",
            body: "",
          });
          setShow(true)
        }
      } else if (action.type === "REDIRECT") {
        window.location.href = action.url
      }
    } else {
      setError("No action found for this QR code")
      setNotification({
        title: "No action found for this QR code",
        body: "",
      });
      setShow(true)
    }
  }

  // Function to handle QR code scan
  const handleScan = qrData => {
    if (qrData) {
      processQrCode(qrData)
    }
  }

  // Function to handle scan errors
  const handleError = scanError => {
    setError(`QR Scan Error: ${scanError.message}`)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>DXC Utsav</title>
      </MetaTags>
      {show ? (
      <Notifications
          toastType="success"
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )}
      <div className="page-content">
        <div className="container-fluid qrDiv">
          {/* <h3>Please find some Qr Code to explore</h3> */}
          <h4 style={{textAlign:"center"}}>Please scan the QR code below</h4>
          <QrReader
            // facingMode={"environment"}
            constraints={{ facingMode:"environment" }}
            delay={300}
            onError={handleError}
            onScan={handleScan}
            onResult={handleScan}
            // style={{ maxHeight:"100vh", padding:"0%"}}
            style={{ width: '100%',maxHeight:"50vh" }}
          />
          {/* <p>{data}</p>
          {error && <p className="error-message">{error}</p>}
          <button onClick={()=>{setisFront(!isFront)}}>Switch</button> 
          <p>{isFront ? "user": "environment"}</p> */}
          {loader.classList.add("loader--hide")}
        </div>
      </div>
    </React.Fragment>
  )
}

export default QrScanComponent
